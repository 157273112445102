import Header from "../Components/header"
import Bread from "../Components/breadcrumb"
import { Grid } from "@mui/material"

const headerTop = {
    position: 'fixed',
    width: '100%',
    top: '0',
    background: '#fff',
    zIndex: '9'
}

export const AuthLayout = props => {
    return (
        <>
            <Grid item md={12} style={headerTop}>
                <Header />
            </Grid>
            <Grid item md={12}>
                <Bread />
            </Grid>
            <Grid item md={12} sx={{ mt: 5 }}>
                {props.children}
            </Grid>
        </>
    )
}