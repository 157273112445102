import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Box,
  ListItem
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import {
  AddOutlined,
  Close
} from "@mui/icons-material";
import { Link } from "react-router-dom";
// toast
import { toast } from "react-toastify";

export const TemplateHeader = ({ formik }) => {
  let rcsTemplateTypes = {
    1: "Text",
    2: "Rich Card",
    3: "Carousel",
  };
  const handleRemoveVariable = (variable) => {
    formik.setFieldValue(
      "templateVariables",
      formik.values.templateVariables.filter((val) => val !== variable)
    );
  };
  return (
    <>
      <Card
        sx={{
          boxShadow: "1px 1px 8px rgb(0 0 0 / 17%)",
          border: "unset",
          width: "100%",
          marginBottom: "25px",
        }}
        variant="outlined"
      >
        <CardContent style={{ padding: "25px" }}>
          <Grid container item spacing={2}>
            <Grid item md={6}>
              <TextField
                label="Template Name*"
                id="templateName"
                defaultValue=""
                size="small"
                fullWidth
                InputLabelProps={{
                  sx: {
                    color: "#92a2b1",
                    fontSize: 15,
                    fontWeight: 400,
                  },
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: 13,
                    fontWeight: 500,
                  },
                }}
                name="templateName"
                onChange={formik.handleChange}
                value={formik.values.templateName}
              />
              {formik.errors.templateName ? (
                <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                  {formik.errors.templateName}
                </Typography>
              ) : null}
            </Grid>
            <Grid item md={6}>
              <FormControl size="small" fullWidth>
                <InputLabel
                  id="templateType"
                  sx={{
                    color: "#92a2b1",
                    fontSize: 15,
                    fontWeight: 400,
                  }}
                >
                  Template Type
                </InputLabel>
                <Select
                  labelId="templateType"
                  id="templateType"
                  label="Template Type"
                  name="templateType"
                  sx={{
                    color: "#000000de",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  value={formik.values.templateType}
                  onChange={formik.handleChange}
                >
                  {Object.keys(rcsTemplateTypes).map((key) => (
                    <MenuItem value={key}>{rcsTemplateTypes[key]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.templateType ? (
                <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                  {formik.errors.templateType}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Grid container item spacing={2} style={{ marginTop: "6px" }}>
            <Grid item md={6}>
              <TextField
                label="Variables"
                id="singleVariable"
                defaultValue=""
                size="small"
                fullWidth
                InputLabelProps={{
                  sx: {
                    color: "#92a2b1",
                    fontSize: 15,
                    fontWeight: 400,
                  },
                }}
                name="singleVariable"
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: 13,
                    fontWeight: 500,
                  },
                  value: formik.values.singleVariable,
                  onChange: formik.handleChange,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0px",
                }}
              >
                {formik.values.templateVariables &&
                  formik.values.templateVariables.length > 0 && (
                    <ListItem
                      style={{
                        width: "auto",
                        padding: "10px 8px 3px 0px",
                      }}
                    >
                      {formik.values.templateVariables.map(
                        (variable, index) => (
                          <Typography
                            sx={{
                              padding: "0px 7px",
                              border: "1px solid #ddd",
                              borderRadius: "20px",
                              textAlign: "center",
                              marginRight: "5px",
                            }}
                            key={index}
                          >
                            <Link
                              to="#"
                              style={{
                                color: "#7d7a7a",
                                fontSize: "12px",
                                textDecoration: "none",
                              }}
                            >
                              {variable}
                              <Close
                                sx={{ fontSize: "12px" }}
                                onClick={() => handleRemoveVariable(variable)}
                              />
                            </Link>
                          </Typography>
                        )
                      )}
                    </ListItem>
                  )}
              </Box>
            </Grid>
            <Grid item md={2}>
              <Button
                type="button"
                variant="contained"
                fullWidth
                sx={{
                  fontSize: "15px",
                  textTransform: "capitalize",
                  background: "#16a6fb",
                  padding: "4px 5px",
                  width: "85px",
                }}
                onClick={() => {
                  if (formik.values.templateVariables.length >= 10) {
                    toast.error(
                      "Maximum of 10 variables is allowed while adding!"
                    );
                  } else {
                    if (formik.values.singleVariable.trim()) {
                      formik.setFieldValue("templateVariables", [
                        ...formik.values.templateVariables,
                        formik.values.singleVariable,
                      ]);
                      formik.setFieldValue("singleVariable", "");
                    }
                  }
                }}
              >
                <AddOutlined sx={{ fontSize: "22px" }} /> Add
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
