import AdminLogin from "../Admin/Authentication/login";
import Admindashboard from "../Admin/Dashboard/dashboard";
import Company from "../Admin/Companies/company";
import Companydetails from "../Admin/Companies/companydetails";
import Templatelist from "../Admin/Companies/templatelist";

const adminPublicRoutes = [
	{ path: '/admin', component: <AdminLogin /> }
];

const adminAuthProtectedRoutes = [
	{ path: '/admin/dashboard', component: <Admindashboard /> },
	{ path: '/admin/companies', component: <Company /> },
	{ path: '/admin/companydetails/:UserID', component: <Companydetails /> },
	{ path: '/admin/templatelist/:UserID/:AppID', component: <Templatelist /> }
];

export { adminPublicRoutes, adminAuthProtectedRoutes }