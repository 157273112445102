import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Container, Grid } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from 'react';
// import { DataGrid } from '@mui/x-data-grid';
import { Link, useParams } from "react-router-dom";
import { authpostAxios } from "../../Utilities/commonAxios";
import moment from 'moment';
import { useDispatch } from "react-redux";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function Templatelist(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

Templatelist.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const dispatch = useDispatch()
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [templateDetailedLists, setTemplateDetailedLists] = useState([])
    const [tableData, setTableData] = useState([]);
    const [searchBtn, setSearchBtn] = useState(true);
    const { AppID } = useParams()
    const getTemplateListAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-template/${AppID}`,
                'data': '',
            }, dispatch).then(TemplateListResponse => setTemplateDetailedLists(TemplateListResponse.data?.data))
    }
    useEffect(() => {
        getTemplateListAPI()
    }, [])
    useEffect(() => {
        let newTableData = [];
        templateDetailedLists && templateDetailedLists.map((templateDetailedList, index) => {
            newTableData.push([templateDetailedList.app_name, templateDetailedList.template_name, templateDetailedList.category_name, ((templateDetailedList.created_at) ? (moment(templateDetailedList.created_at).format('DD-MM-YYYY HH:MM:SS A')) : ('')), templateDetailedList.whatsapp_approval_status, '', templateDetailedList.template_code, templateDetailedList.template_language_code])
        })
        setTableData(newTableData)
    }, [templateDetailedLists])

    // table-options
    const options = {
        search: searchBtn,
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
    }
    // table-columns
    const columns = [
        "App Name",
        {
            name: "Template",
            options: {
                filterOptions: { fullWidth: true },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Typography sx={{ fontSize: 15, fontWeight: 400 }}>
                                {`${tableMeta.rowData[1]}`}
                            </Typography>
                            <Typography sx={{ fontSize: 15, fontWeight: 400 }}>
                                <strong>code:</strong>{` ${tableMeta.rowData[6]}`}
                            </Typography>
                            <Typography sx={{ fontSize: 15, fontWeight: 400 }}>
                                <strong>Language:</strong>{` ${tableMeta.rowData[7]}`}
                            </Typography>
                        </>
                    )
                }
            }
        },
        "Category",
        "Date/time",
        "Approved Status",
        {
            name: "Action",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Button variant='contained' sx={{ fontSize: '12px', fontWeight: 400, textTransform: 'capitalize', p: 1 }}>View More</Button>
                        </>
                    );
                },
            },
        },
        {
            name: "TemplateCode",
            options: {
                display: false
            }
        },
        {
            name: "TemplateLanguageCode",
            options: {
                display: false
            }
        }
    ];

    return (
        <Container>
            <Grid container item spacing={2}>
                <Grid item md={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Templates" sx={{ textTransform: 'capitalize', }} {...a11yProps(0)} />
                                {/* <Tab label="Webhooks" sx={{ textTransform: 'capitalize', }} {...a11yProps(1)} /> */}
                            </Tabs>
                        </Box>
                        <Templatelist value={value} index={0}>
                            <Grid container item>
                                <Grid item md={12} sx={{ mt: 2 }}>
                                    <div style={{ width: '100%' }}>
                                        <MUIDataTable
                                            columns={columns}
                                            data={tableData}
                                            options={options}
                                            className="custom-datatable-class" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Templatelist>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}