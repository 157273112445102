
import { Button, Card, CardContent, Container, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import Time from '@mui/icons-material/AccessTimeFilledOutlined';
import Progress from '@mui/icons-material/RotateRightOutlined';
import { style } from './style';
import Total from '@mui/icons-material/CalculateOutlined';
import File from '@mui/icons-material/InsertDriveFileOutlined';
import Money from '@mui/icons-material/MoneyOutlined';

const cardIcon = [Total, Progress, Time, File, Money]

const MessageStatusReport = (props) => {
    const { appDetails,getOverallReportDetails,getDailyReportDetails } = props;    
    return (
        <>
            <Grid container item spacing={2}>
                <Grid item md={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                        Over All: {appDetails.app_name}
                    </Typography>
                </Grid>
                {getOverallReportDetails ? getOverallReportDetails.map((getOverallReportDetail, index) => {
                    let Icon = cardIcon[index];
                    return (
                        <Grid item md={3}>
                            <Grid sx={style[`reportBox${(index + 1)}`]}>
                                <Grid container item sx={{ p: 2 }}>
                                    <Grid item md={3} sx={{ textAlign: 'start', alignSelf: 'center' }}>
                                        <Grid style={style[`iconStyle${(index + 1)}`]}>
                                            <Icon sx={{ color: '#fff', transform: 'rotate(45deg)', }} />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={9} sx={{ textAlign: 'end', alignSelf: 'center' }}>
                                        <Typography style={style.subHeading}>
                                            {getOverallReportDetail.status_name}
                                        </Typography>
                                        <Typography style={style.totalnumber}>
                                            {(getOverallReportDetail.count).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }) : (<Grid item container>
                    <Grid item md={12}>
                    <Grid sx={{ width: '100%', border:'1px solid #d32f2fe3', color: '#d32f2fe3', mt: 2, fontWeight: 500, textAlign: 'center', borderRadius: '4px', p: 1 }}>No Data</Grid>
                    </Grid>
                </Grid>)}
            </Grid>
            <Grid container item spacing={2} sx={{ mt: 2 }}>
                <Grid item md={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>
                        Today: {appDetails.app_name}
                    </Typography>
                </Grid>
                {getDailyReportDetails ? getDailyReportDetails.map((getDailyReportDetail, index) => {
                    let Icon = cardIcon[index];
                    return (
                        <Grid item md={3}>
                            <Grid sx={style[`reportBox${(index + 1)}`]}>
                                <Grid container item sx={{ p: 2 }}>
                                    <Grid item md={3} sx={{ textAlign: 'start', alignSelf: 'center' }}>
                                        <Grid style={style[`iconStyle${(index + 1)}`]}>
                                            <Icon sx={{ color: '#fff' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={9} sx={{ textAlign: 'end', alignSelf: 'center' }}>
                                        <Typography style={style.subHeading}>
                                            {getDailyReportDetail.status_name}
                                        </Typography>
                                        <Typography style={style.totalnumber}>
                                            {(getDailyReportDetail.count).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }) : (<Grid item container>
                    <Grid item md={12}>
                        <Grid sx={{ width: '100%', border:'1px solid #d32f2fe3', color: '#d32f2fe3', mt: 2, fontWeight: 500, textAlign: 'center', borderRadius: '4px', p: 1 }}>No Data</Grid>
                    </Grid>
                </Grid>)}
            </Grid>
        </>
    )
}

export default MessageStatusReport