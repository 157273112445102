import { Grid, Typography, Card, CardContent, Button } from "@mui/material";
// toast
import { TextSection } from "./TextSection";
import { RichCardSection } from "./RichCardSection";
import { CarouselSection } from "./CarouselSection";
import { useCallback } from "react";

export const TemplateFooter = ({ formik }) => {
  const handleDeleteSuggestedAction = (actionId) => {
    formik.setFieldValue(
      "selectedSuggestedActions",
      formik.values.selectedSuggestedActions.filter(
        (action) => action !== actionId
      )
    );
  };
  const handleVariableAddition = (variable, field=null,index = null) => {
    if(formik.values.templateType === "1"){
      formik.setFieldValue("textMessage",`${formik.values.textMessage}[${variable}]`)
    } else if(formik.values.templateType === "2"){
      formik.setFieldValue(`richCard${field}`,`${formik['values'][`richCard${field}`]}[${variable}]`)
    } else if(formik.values.templateType === "3"){
      
    }
  };
  return (
    <>
      <Card
        sx={{
          boxShadow: "1px 1px 8px rgb(0 0 0 / 17%)",
          border: "unset",
          width: "100%",
          marginBottom: "25px",
          padding: "10px",
        }}
        variant="outlined"
      >
        <CardContent style={{ padding: "15px" }}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              sx={{ color: "red", fontSize: 14, marginBottom: "15px" }}
            >
              NOTE: Variables to be added in between square brackets i.e [var].
            </Typography>
          </Grid>
          <Grid container item spacing={2} className="">
            {formik.values.templateType === "1" && (
              <TextSection
                formik={formik}
                handleDeleteSuggestedAction={handleDeleteSuggestedAction}
                handleVariableAddition={handleVariableAddition}
              />
            )}
            {formik.values.templateType === "2" && (
              <RichCardSection
                formik={formik}
                handleDeleteSuggestedAction={handleDeleteSuggestedAction}
                handleVariableAddition={handleVariableAddition}
              />
            )}
            {formik.values.templateType === "3" && (
              <CarouselSection
                formik={formik}
                handleDeleteSuggestedAction={handleDeleteSuggestedAction}
                handleVariableAddition={handleVariableAddition}
              />
            )}
          </Grid>
          <Grid item md={12} sx={{ textAlign: "right", marginTop: "20px" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontSize: "15px",
                textTransform: "capitalize",
                background: "#16a6fb",
                padding: "6px",
                width: "85px",
              }}
            >
              Submit
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
