import { Container, Grid, Typography } from "@mui/material"

const Footersectionbottom = {
    backgroundColor: '#011c63',
    padding: '20px',
}

const Footer = () => {
    return (
        <>
            <Grid style={Footersectionbottom}>
                <Container>
                    <Typography sx={{ color: '#fff', textAlign: 'center' }}>© Copyright 2022 CARES, Inc. All Right Reserved</Typography>
                </Container>
            </Grid>
        </>
    )
}


export default Footer