import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Container, Grid, TextField, Typography } from "@mui/material"
import axios from 'axios';
import { CopyBlock, dracula } from "react-code-blocks";
import _ from 'lodash';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';


const leftSide = {
    backgroundColor: 'rgb(247 247 247)',
    height: '100vh',
    padding: '20px',
    width: '100%',
    marginTop: '0px',
    borderRadius: '5px'
}

const leftSideheading = {
    fontSize: '13px',
    color: '#454141',
    marginBottom: '10px',
    fontWeight: '600'
}
const linkStyle = {
    color: '#454141',
    fontSize: '13px', mb: '2px',
    fontWeight: '600',
    display: 'block',
    textDecoration: 'unset',
    paddingBottom: '5px',
}

const ApiDocs = () => {
    // process collection
    const [collectionData, setCollectionData] = useState(null);
    const readDocsFile = async () => {
        await axios.get(`${process.env.REACT_APP_BACKENDURL}Whatsapp_collection.json`).then(file => setCollectionData(file?.data?.item))
    }
    useEffect(() => {
        readDocsFile()
    }, [])
    // update url data section
    const [selectedDiv, setSelectedDiv] = useState(null);
    const [urlSection, setUrlSection] = useState([]);
    useEffect(() => {
        if (collectionData) {
            Promise.all(collectionData?.map((data, index) => {
                let requestBody = (data?.request?.body?.raw) ?? '';
                let divId = ((data.name).trim()).replaceAll(' ', '_')
                let viewJsonData = {
                    'name': data.name,
                    'url': (data.request.url.raw) ?? '',
                    'header': (data?.request?.header) ?? '',
                    'request': ((requestBody) ? (JSON.stringify(JSON.parse(requestBody), null, 2)) : ('')),
                    'response': (data?.response) ?? ''
                }
                return (
                    (!selectedDiv && index === 0) ? (
                        <Link style={linkStyle} key={index} onClick={showDivSection(divId, viewJsonData)} className={(selectedDiv === divId) ? ('active') : ('')}>
                            {data.name}
                        </Link>
                    ) : (
                        <Link style={linkStyle} key={index} onClick={() => showDivSection(divId, viewJsonData)} className={(selectedDiv === divId) ? ('active') : ('')}>
                            {data.name}
                        </Link>
                    )
                )
            })).then(updatedurlSection => {
                setUrlSection(updatedurlSection)
            })
        }
    }, [collectionData, selectedDiv])
    // onclick function
    const [viewSection, setViewSection] = useState({})
    const showDivSection = (divId, viewJsonData) => {
        setSelectedDiv(divId)
        setViewSection(viewJsonData)
    }
    // update response section
    const [totalResponses, setTotalResponses] = useState([])
    useEffect(() => {
        if (!_.isEmpty(viewSection)) {
            let individualResponses = viewSection.response;
            if (individualResponses) {
                Promise.all(individualResponses.map((individualResponse, index) => {
                    return (
                        <Typography sx={{ fontSize: '13px', color: '#383434', fontWeight: '600', mb: 2 }} key={index}>Response - {individualResponse.name}: <CopyBlock language="javascript" text={JSON.stringify(JSON.parse(individualResponse.body), null, 2)} theme={dracula} showLineNumbers={false} /></Typography>
                    )
                })).then(updatedTotalResponses => {
                    setTotalResponses(updatedTotalResponses)
                })
            }
        }
    }, [viewSection])
    // update header and view data section
    const [updatedViewSection, setUpdatedViewSection] = useState([]);
    useEffect(() => {
        if (!_.isEmpty(viewSection)) {
            let individualHeaders = viewSection.header;
            let newHeaders = {};
            if (individualHeaders) {
                individualHeaders.map((individualHeader) => {
                    newHeaders[individualHeader.key] = individualHeader.value;
                })
            }
            setUpdatedViewSection(
                <>
                    <Grid sx={{ marginTop: '5px', }}>
                        <Typography sx={{ fontSize: '16px', color: '#000', fontWeight: '600', mb: 1 }}>{viewSection.name}</Typography>
                        <Typography sx={{ fontSize: '13px', color: '#383434', fontWeight: '600', mb: 1 }}>URL - {viewSection.url}</Typography>
                        {(viewSection.header.length > 0) ? (
                            <Typography sx={{ fontSize: '13px', color: '#383434', fontWeight: '600', mb: 2 }}>Header:
                                <CopyBlock language="javascript" text={JSON.stringify(newHeaders, null, 2)} theme={dracula} showLineNumbers={false} />
                            </Typography>) : ('')}
                        {(viewSection.request) ? (<Typography sx={{ fontSize: '13px', color: '#383434', fontWeight: '600', mb: 2 }}>Request: <CopyBlock language="javascript" text={viewSection.request} theme={dracula} showLineNumbers={false} /></Typography>) : ('')}
                        {totalResponses}
                    </Grid>
                </>)
        }
    }, [viewSection, totalResponses])
    return (
        <>
            <Header />
            <Container>
                <Grid container item spacing={2} sx={{ mt: 9 }}>
                    <Grid item md={3} style={leftSide}>
                        <Typography style={leftSideheading}>Whatsapp API</Typography>
                        {urlSection}
                    </Grid>
                    <Grid item md={9}>
                        {updatedViewSection}
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    )
}

export default ApiDocs