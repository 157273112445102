
import { Button, Container, FormControl, Grid, InputAdornment, OutlinedInput, TextField, } from "@mui/material";
import Typography from '@mui/material/Typography';
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Editicon from '@mui/icons-material/ModeEditOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { purple } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from 'react';
import { authpostAxios } from "../../Utilities/commonAxios";
import moment from "moment";
import Switch from '@mui/material/Switch';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// toast
import { toast } from "react-toastify";
import _ from "lodash";

// modal-start
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
// modal-close

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
        backgroundColor: purple[700],
    },
}));

const Company = () => {
    const dispatch = useDispatch()
    const [userDetailedLists, setUserDetailedLists] = useState([])
    const [tableData, setTableData] = useState([]);
    const getUserDetailedListAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/admin/get-userdetails`,
                'data': ''
            },dispatch).then(userDetailedListResponse => setUserDetailedLists(userDetailedListResponse.data?.data))
    }
    useEffect(() => {
        getUserDetailedListAPI()
    }, [])
    useEffect(() => {
        let newTableData = [];
        userDetailedLists && userDetailedLists.map((userDetailedList, index) => {
            newTableData.push([`${userDetailedList.firstname} ${userDetailedList.lastname}`, userDetailedList.companyname, userDetailedList.emailid, userDetailedList.credit_balance, ((userDetailedList.created_at) ? (moment(userDetailedList.created_at).format('DD-MM-YYYY HH:MM:SS A')) : ('')), ((userDetailedList.status === 1) ? ('Active') : ('Inactive')), '', userDetailedList.userid, userDetailedList.status])
        })
        setTableData(newTableData)
    }, [userDetailedLists])
    // status change
    const handleStatusChange = async (userId, statusType) => {
        const { data: statusResponse } = await authpostAxios(
            {
                'url': ((statusType === 'inactive') ? (`${process.env.REACT_APP_BACKENDURL}api/admin/upd-userinactive`) : (`${process.env.REACT_APP_BACKENDURL}api/admin/upd-useractive`)),
                'data': { "appUserId": userId }
            },dispatch);
        if (statusResponse.flag === 1) toast.success(statusResponse.message)
        else toast.error(statusResponse.message)
        getUserDetailedListAPI()
    }
    const [searchBtn, setSearchBtn] = useState(true);
    // table-options
    const options = {
        search: searchBtn,
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
    }
    // table-columns
    const columns = [
        {
            name: "Name",
            options: {
                filterOptions: { fullWidth: true },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Link to={`/admin/companydetails/${btoa(tableMeta.rowData[7])}`} style={{ textDecoration: 'none', color:'rgb(25 118 210)', fontWeight:'500' }}>{_.upperFirst(_.toLower(tableMeta.rowData[0]))}</Link>
                        </>
                    )
                }
            }
        },
        "Company Name",
        "Email",
        "Credit Balance",
        "Date/Time",
        "Status",
        {
            name: "Action",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Switch onChange={() => handleStatusChange(tableMeta.rowData[7], tableMeta.rowData[8])} defaultChecked={((tableMeta.rowData[8] === 1) ? (true) : (false))} />
                        </>
                    );
                },
            },
        },
        {
            name: "userId",
            options: {
                display: false
            }
        },
        {
            name: "statusValue",
            options: {
                display: false
            }
        }
    ];

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [age, setAge] = useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item md={6}>
                        <Typography sx={{ color: '#32393a', fontSize: 20, fontWeight: 500 }}>
                            Dashboard
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#32393a', fontSize: 14, }}>
                            View how your apps are performing and manage them directly from this dashboard
                        </Typography>
                    </Grid>
                    <Grid item md={6} sx={{ textAlign: 'end' }}>
                        <Button variant="contained" 
                        // onClick={handleClickOpen}
                        >Add New</Button>
                    </Grid>
                    <Grid item md={12} sx={{ mt: 5 }}>
                        <MUIDataTable
                            columns={columns}
                            data={tableData}
                            options={options} 
                            className="custom-datatable-class" />
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}

export default Company