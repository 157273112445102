import { Button, Grid, Snackbar, TextField, Typography } from "@mui/material"
import { Link, } from "react-router-dom";
import { useState } from "react";


const Forgotpassword = () => {
    const [snackBarMessage, setSnackBarMessage] = useState({
        type: 'success',
        desc: 'Reset password link was successfully sent to your email id',
        enable: true,
    });

    const handleClose = () => {
        setSnackBarMessage({
            type: 'success',
            desc: '',
            enable: false,
        });
    };
    let vertical = 'top';
    let horizontal = 'right';

    const msgboxcustom = {
        background: '#51c997', color: '#fff', width: '380px',
    }

    return (
        <>
            <Grid className="second-section">
                <Grid item md={12} sx={{ position: 'relative' }}>
                    <Grid className="outer-box-style">
                        <Typography sx={{ fontSize: 15, color: '#32393a', fontWeight: 500, marginBottom: 1.5 }} >
                            Forgot your password?
                        </Typography>
                        <Typography sx={{ fontSize: 20, color: '#32393a', fontWeight: 500, marginBottom: 1 }}>
                            Enter your Email id to reset your <br />password
                        </Typography>
                        <form>
                            <Grid className="mt-4 mb-3">
                                <TextField id="outlined-basic" size="small" label="Email Id" name="emilid" fullWidth />
                            </Grid>
                            <Grid className="mt-4 mb-4">
                                <Button variant="contained" fullWidth sx={{ backgroundColor: '#32393a', borderRadius: 1.3, padding: 1.5 }}>Send</Button>
                            </Grid>
                            <Grid className="mt-3">
                                <Typography sx={{ textAlign: 'center', fontFontWeight: 500, }}>
                                    <Link to="/login" style={{ color: '#32393a', }}>Return to Sign in</Link>
                                </Typography>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Forgotpassword