import * as React from 'react';
import { Container, Grid, Breadcrumbs, Typography } from "@mui/material"
import { Link } from 'react-router-dom';


const Bread = () => {
    return (
        <>
            <Grid sx={{ m: 3 }}>
                <Container>
                    <Grid item md={12}>
                        <Breadcrumbs separator="/" aria-label="breadcrumbs">
                            <Link className='breadcrumbs-style' to="/admin/dashboard">
                                Dashboard
                            </Link>
                            <Typography sx={{ color: '#32393a', fontSize: 13, fontWeight: 500 }}>WhatsApp</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Bread