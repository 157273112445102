import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform  } from 'redux-persist-transform-encrypt';

const secretKey = 'wh@tsappReact'

const reducerList = combineReducers({
  auth:authReducer
})

const persistConfig = {
  key: 'userSession',
  storage,
  transforms: [encryptTransform({secretKey })]
}

const persistedReducer = persistReducer(persistConfig, reducerList);

export const store = configureStore({ reducer: persistedReducer })

export const persistor = persistStore(store);