import { Grid } from "@mui/material"
import Loginbgsection from "../Authentication/loginbgsection";

export const NonAuthLayout = props => {
    return (
        <>
            <Grid style={{ height: '100vh', overflow: 'hidden' }}>
                <Grid container>
                    <Grid item md={5} xs={12}>
                        <Loginbgsection />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}