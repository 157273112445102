import { Container, Grid, Typography } from "@mui/material"
import Headerlogo from '../Images/header-logo.png'

const headerStyle = {
    padding: '10px',
    backgroundImage: 'linear-gradient(117deg, #c9fffc 1%, #decffb 48%, #ffabfc 100%)',
    position: 'fixed',
    width: '100%',
    zIndex: 9,
    boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 40px -5px',
    top:0,
}

const Header = () => {
    return (
        <>
            <Grid style={headerStyle}>
                <Container>
                    <Grid container item spacing={1}>
                        <Grid item md={5}>
                        <img src={Headerlogo} />
                        </Grid>
                        <Grid item md={7} sx={{alignSelf:'center', }}>
                         <Typography sx={{fontSize:'18px', color:'#000', fontWeight:'600',}}>API Documentation</Typography>
                         </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Header