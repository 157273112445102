import { Button, Grid, TextField, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { authLogin } from "../Reduxstore/authSlice";
import { useDispatch } from 'react-redux';
// toast
import { toast } from "react-toastify";

const Loginsection = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // form submission
    const loginForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Username"),
            password: Yup.string().required("Please Enter Password"),
        }),
        onSubmit: async (values) => {
            let loginResponseData = await dispatch(authLogin({ emailid: values.email, userpassw: values.password }))
            if (loginResponseData.payload.flag === 1) {
                localStorage.setItem('userToken', loginResponseData.payload.data.userToken)
                navigate('/dashboard')
                toast.success(loginResponseData.payload.message);
            } else {
                localStorage.removeItem('userToken')
                toast.error(loginResponseData.payload.message);
            }
        }
    });
    return (
        <>
            <Grid className="second-section">
                <Grid item md={12}>
                    <Grid className="outer-box-style">
                        <Typography sx={{ fontSize: 18, color: '#32393a', fontWeight: 500, }} >
                            Welcome back,
                        </Typography>
                        <Typography sx={{ fontSize: 24, color: '#32393a', fontWeight: 500, marginBottom: 1 }}>
                            Sign in to continue
                        </Typography>
                        <form onSubmit={loginForm.handleSubmit}>
                            <Grid className="mb-2 mt-3">
                                <TextField size="small" autoComplete='off' label="Email" name="email" onChange={loginForm.handleChange} value={loginForm.values.email} variant="outlined" sx={{ width: '45ch' }}/>
                                {(loginForm.errors.email) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{loginForm.errors.email}</Typography>) : (null)}
                            </Grid>
                            <Grid className="mb-2 mt-3">
                                <TextField type="password" size="small" autoComplete='off' label="Password" name="password" onChange={loginForm.handleChange} value={loginForm.values.password} variant="outlined" sx={{ width: '45ch' }} />
                                {(loginForm.errors.password) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{loginForm.errors.password}</Typography>) : (null)}
                            </Grid>
                            <Grid className="mt-1 mb-3">
                                <Link to="/forgotpassword" sx={{ textDecoration: 'none' }} className="forgot-style">
                                    Forgot Password?
                                </Link>
                            </Grid>
                            <Grid className="mt-2 mb-3">
                                <Button variant="contained" type='submit' fullWidth sx={{ backgroundColor: '#32393a', borderRadius: 1.3, padding: 1.5 }}>Sign in</Button>
                            </Grid>
                            <Grid className="mt-2">
                                <Typography sx={{ textAlign: 'center', color: '#32393a', fontSize: 13 }}>
                                    Don’t have an account ? <Link to="/register" sx={{ textDecoration: 'none' }} className="sign-up-style">Sign up</Link>
                                </Typography>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default Loginsection