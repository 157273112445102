import { Button, Card, Container, Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import Addtaps from "./addwalletbalancetaps";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";

const Index = () => {
    const authState = useSelector(state => state.auth);
    return (
        <>
            <Container sx={{ mb: 4 }}>
                <Grid container>
                    <Grid item md={12}>
                        <Typography sx={{ color: '#32393a', fontSize: 20, fontWeight: 500 }}>
                            My Wallet & Usage
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#32393a', fontSize: 14, }}>
                            All your usage from WhatsApp self serve dashboard will be deducted from this wallet
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item spacing={2} sx={{ mt: 3, }}>
                    <Grid item md={12} sx={{ alignItems: 'center', }}>
                        <Card sx={{ p: 2, mb: 3, boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset' }} variant="outlined">
                            <Typography sx={{ fontSize: 16, color: '#32393a', fontWeight: 500, mb: 2 }}>
                                Available Balance
                            </Typography>
                            <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 2, }}>
                                <CurrencyRupeeIcon style={{ fontSize: 20, color: '#32393a', marginRight: 10 }} /> {authState?.walletAmount}
                            </Typography>
                            <Link to="/add-to-wallet">
                                <Button sx={{ fontSize: 14, fontWeight: 400 }} variant="contained">Add Balance</Button>
                            </Link>
                        </Card>
                    </Grid>
                    <Grid item md={12} sx={{ alignItems: 'center', }}>
                        <Card sx={{ p: 2, mb: 3, boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset' }} variant="outlined">
                            <Addtaps />
                        </Card>
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}

export default Index