import './Style/style.css'
import { homeRoutes, authProtectedRoutes, publicRoutes, authProtectedConnectRoutes, authProtectedPaymentRoutes, errorRoutes, apiDocsRoutes } from './Routes/index';
import { adminPublicRoutes, adminAuthProtectedRoutes } from './Routes/admin';
import { NonAuthMiddleware, AuthMiddleware, AuthConnectMiddleware, AdminNonAuthMiddleware, AdminAuthMiddleware } from './Routes/auth';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomeLayout } from './Layouts/homeLayout';
import { NonAuthLayout } from './Layouts/nonAuthLayout';
import { AuthLayout } from './Layouts/authLayout';
import { AdminAuthLayout } from './Layouts/Admin/authLayout';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {homeRoutes.map((homeRoute, key) => (
            <Route path={homeRoute.path} element={<HomeLayout>{homeRoute.component}</HomeLayout>} key={key}
              exact={true} />
          ))} 
          {apiDocsRoutes.map((apiDocsRoute, key) => (
            <Route path={apiDocsRoute.path} element={apiDocsRoute.component} key={key}
              exact={true} />
          ))}                
          {publicRoutes.map((publicRoute, key) => (
            <Route path={publicRoute.path} element={<NonAuthMiddleware><NonAuthLayout>{publicRoute.component}</NonAuthLayout></NonAuthMiddleware>} key={key}
              exact={true} />
          ))}
          {authProtectedRoutes.map((authProtectedRoute, key) => (
            <Route path={authProtectedRoute.path} element={<AuthMiddleware><AuthLayout>{authProtectedRoute.component}</AuthLayout></AuthMiddleware>} key={key} exact={true} />
          ))}
          {authProtectedPaymentRoutes.map((authProtectedPaymentRoute, key) => (
            <Route path={authProtectedPaymentRoute.path} element={<AuthMiddleware><AuthLayout>{authProtectedPaymentRoute.component}</AuthLayout></AuthMiddleware>} key={key} exact={true} />
          ))}
          {authProtectedConnectRoutes.map((authProtectedConnectRoute, key) => (
            <Route path={authProtectedConnectRoute.path} element={<AuthMiddleware><AuthConnectMiddleware><AuthLayout>{authProtectedConnectRoute.component}</AuthLayout></AuthConnectMiddleware></AuthMiddleware>} key={key} exact={true} />
          ))}
          {adminPublicRoutes.map((adminPublicRoute, key) => (
            <Route path={adminPublicRoute.path} element={<AdminNonAuthMiddleware><NonAuthLayout>{adminPublicRoute.component}</NonAuthLayout></AdminNonAuthMiddleware>} key={key}
              exact={true} />
          ))}
          {adminAuthProtectedRoutes.map((adminAuthProtectedRoute, key) => (
            <Route path={adminAuthProtectedRoute.path} element={<AdminAuthMiddleware><AdminAuthLayout>{adminAuthProtectedRoute.component}</AdminAuthLayout></AdminAuthMiddleware>} key={key} exact={true} />
          ))}
          {errorRoutes.map((errorRoute, key) => (
            <Route path={errorRoute.path} element={errorRoute.component} key={key} exact={true} />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
