import { Button, Container, Grid, } from "@mui/material"
import { Link } from "react-router-dom";
import Headerlogo from '../Images/header-logo.png'
import { List, ListItem } from '@mui/material';
import { HashLink } from 'react-router-hash-link';

const headerStyle = {
    padding: '2px',
    backgroundImage: 'linear-gradient(117deg, #c9fffc 1%, #decffb 48%, #ffabfc 100%)',
    position: 'fixed',
    width: '100%',
    zIndex: 9,
    boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 40px -5px',
}

const menustyle = {
    textDecoration: 'unset', fontWeight: 500, fontSize: '15px', width: 'unset', color: '#2c3e50', cursor: 'pointer'
}

const Header = () => {
    return (
        <>
            <Grid style={headerStyle}>
                <Container>
                    <Grid container>
                        <Grid container item spacing={1} sx={{ alignItems: 'center' }}>
                            <Grid item md={3}>
                                <Link to="/">
                                    <img src={Headerlogo} />
                                </Link>
                            </Grid>
                            <Grid item md={9}>
                                <List sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <ListItem style={menustyle} component={Link} to='/'>
                                        Home
                                    </ListItem>
                                    <ListItem style={menustyle} component={HashLink} to="/#services" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: "end" })}>
                                        Services
                                    </ListItem>
                                    <ListItem style={menustyle} component={HashLink} to="/#product" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                                        Product
                                    </ListItem>
                                    <ListItem style={menustyle} component={HashLink} to="/#contact" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                                        Contact
                                    </ListItem>
                                    <ListItem style={menustyle} component={Link} to='/login'>
                                        Login
                                    </ListItem>
                                    <ListItem sx={{ width: 'unset' }}>
                                        <Button variant="contained" sx={{ textTransform: 'capitalize', fontSize: 13 }}>Get Started</Button>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Header