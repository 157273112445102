import { Grid } from "@mui/material"
import Logo from '../Images/login-logo.png';
import Loginbanner from '../Images/login-img.png';
import { Link } from "react-router-dom";

const Loginbgsection = () => {
    const firstsection = {
        backgroundColor: "#f6f8f9",
        padding: 20,
        height: '100vh',
    };
    return (
        <>
            <Grid style={firstsection}>
                <Grid item md={12} component={Link} to='/'>
                    <img src={Logo} alt="Logo"/>
                </Grid>
                <Grid item md={12} className="banner-img">
                    <img src={Loginbanner} alt="Banner" />
                </Grid>
            </Grid>
        </>
    )
}

export default Loginbgsection