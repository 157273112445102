import {
  Grid,
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import { ExpandMore, CloudUpload } from "@mui/icons-material";
import styled from "@emotion/styled";
import { SuggestedActionMenu, SuggestedActionMenuDiv } from "./toggleButton";
import { VariableDropdown } from "./VariableDropdown";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export const RichCardSection = ({ formik, handleDeleteSuggestedAction,handleVariableAddition }) => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <Grid item md={6}>
        <Accordion>
          <AccordionSummary
            sx={{
              fontSize: "15px",
              fontWeight: "600",
              color: "#000000ba",
            }}
            expandIcon={<ExpandMore />}
            aria-controls="richPanel-content"
            id="richPanel-header"
          >
            <Grid container item spacing={1}>
              <Grid item md="12">
                Rich Card
              </Grid>
            </Grid>
          </AccordionSummary>
          <Divider sx={{ border: "1px solid #c8c8c8" }} />
          <AccordionDetails>
            <Grid container item spacing={2} sx={{ marginTop: "5px" }}>
              <Grid md={12} item sx={{}}>
                <TextField
                  label="Card Title"
                  id="richCardTitle"
                  defaultValue=""
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    sx: {
                      color: "#92a2b1",
                      fontSize: 15,
                      fontWeight: 400,
                    },
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: 13,
                      fontWeight: 500,
                    },
                    name:"richCardTitle",
                    value:formik.values.richCardTitle
                  }}
                  onChange={formik.handleChange}
                />
              </Grid>
              {formik.values.templateVariables.length > 0 && (
                <Grid item md={12}>
                  <VariableDropdown
                    templateVariables={formik.values.templateVariables} handleVariableAddition={handleVariableAddition} field="Title"
                  />
                </Grid>
              )}
              <Grid md={12} item sx={{}}>
                <TextField
                  id="richCardDescription"
                  label="Card Description"
                  multiline
                  rows={3}
                  defaultValue=""
                  fullWidth
                  placeholder="Card Description ( 1-2000 characters)"
                  InputLabelProps={{
                    sx: {
                      color: "#92a2b1",
                      fontSize: 15,
                      fontWeight: 400,
                    },
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: 13,
                      fontWeight: 500,
                    },
                    name:"richCardDescription",
                    value:formik.values.richCardDescription
                  }}
                  onChange={formik.handleChange}
                />
              </Grid>
              {formik.values.templateVariables.length > 0 && (
                <Grid item md={12}>
                  <VariableDropdown
                    templateVariables={formik.values.templateVariables} handleVariableAddition={handleVariableAddition} field="Description"
                  />
                </Grid>
              )}
              <Grid md={6} item sx={{}}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      color: "#92a2b1",
                      fontSize: 15,
                      fontWeight: 400,
                    }}
                  >
                    Card Orientation
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={age}
                    label="Template Type"
                    onChange={handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Text</MenuItem>
                    <MenuItem value={20}>Rich Card</MenuItem>
                    <MenuItem value={30}>Carousel</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid md={6} item sx={{}}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      color: "#92a2b1",
                      fontSize: 15,
                      fontWeight: 400,
                    }}
                  >
                    Alignment
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={age}
                    label="Template Type"
                    onChange={handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Text</MenuItem>
                    <MenuItem value={20}>Rich Card</MenuItem>
                    <MenuItem value={30}>Carousel</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid md={12} item sx={{}}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      color: "#92a2b1",
                      fontSize: 15,
                      fontWeight: 400,
                    }}
                  >
                    Media Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={age}
                    label="Template Type"
                    onChange={handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Text</MenuItem>
                    <MenuItem value={20}>Rich Card</MenuItem>
                    <MenuItem value={30}>Carousel</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} sx={{ mb: 1 }}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUpload />}
                  fullWidth
                >
                  Upload file
                  <VisuallyHiddenInput type="file" />
                </Button>
              </Grid>
              <Grid item md={12}>
                <SuggestedActionMenu formik={formik} />
              </Grid>
              <Grid item md={12}>
                {formik.values.selectedSuggestedActions.map((action, index) => (
                  <SuggestedActionMenuDiv
                    actionId={action}
                    index={index}
                    handleDeleteSuggestedAction={handleDeleteSuggestedAction}
                  />
                ))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
