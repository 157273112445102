import { Button, Card, Container, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { load } from '@cashfreepayments/cashfree-js';
import { authpostAxios } from "../Utilities/commonAxios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


const MakePayment = () => {
    const dispatch = useDispatch()
    const submitWalletForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            walletAmount: ''
        },
        validationSchema: Yup.object({
            walletAmount: Yup.string().matches(/^[0-9]+$/, 'Wallet Amount must be numberic').required('Please enter wallet amount')
        }),
        onSubmit: async (values) => {
            await authpostAxios(
                {
                    'url': `${process.env.REACT_APP_BACKENDURL}api/payment/create-order`,
                    'data': { orderAmount: values.walletAmount }
                },dispatch).then(async (orderResponse) => {
                    let orderResponseData = orderResponse.data;
                    if (orderResponseData.flag === 1) {
                        let paymentDecryptedData = JSON.parse(atob(orderResponseData.data.payments))
                        const cashfree = await load({ mode: paymentDecryptedData.env })
                        cashfree.checkout({ paymentSessionId: paymentDecryptedData.session_id })
                    } else {
                        toast.error(orderResponseData.message)
                    }
                })
        }
    })
    return (
        <>
            <Container sx={{ mt: 6 }}>
                <Grid container item>
                    <Grid item md={4}>
                    </Grid>
                    <Grid item md={4}>
                        <form onSubmit={submitWalletForm.handleSubmit}>
                        <Card sx={{ p: 3, mb: 3, boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset' }} variant="outlined">
                        <Typography variant="body2" sx={{ color: '#32393a', fontSize: 14, textAlign: "center" }}>
                                    Add money to your wallet, manage your account, and all usage from the WhatsApp self-serve dashboard will be deducted from this wallet.
                                </Typography>
                                <Grid item spacing={1} container sx={{ mt: 1 }}>
                                    <Grid item md={12}>
                                        <TextField
                                            label="Enter Recharge Amount"
                                            id="outlined-size-small"
                                            size="small"
                                            fullWidth
                                            name="walletAmount"
                                            values={submitWalletForm.values.walletAmount}
                                            onChange={submitWalletForm.handleChange}
                                            autoComplete="off"
                                        />
                                        {(submitWalletForm.errors.walletAmount) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitWalletForm.errors.walletAmount}</Typography>) : (null)}
                                    </Grid>
                                    {/* <Grid item md={4} sx={{ textAlign: 'center' }}>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="include" control={<Radio />} label="Include Gst" />
                                                <FormControlLabel value="exclude" control={<Radio />} label="Excluding Gst" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item md={12} sx={{
                                        mt: 1, display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <Button variant="contained" type="submit" sx={{
                                            fontSize: 14, fontWeight: 400, textTransform: 'capitalize', p: 1, width: '100%', lineHeight:'unset'
                                        }}><LockOutlinedIcon />Pay Now</Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default MakePayment