import { Button, FormControlLabel, Grid, TextField, Typography } from "@mui/material"
import Checkbox from '@mui/material/Checkbox';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
// toast
import { toast } from "react-toastify";
import { nonAuthpostAxios } from "../Utilities/commonAxios";

const Register = () => {
    const navigate = useNavigate();
    const linkstyle = {
        color: '#16a6fb', fontSize: 13, fontWeight: 500, textDecoration: 'none'
    };

    const registerForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            mobile: '',
            password: '',
            isTermsAgreed: false
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Please Enter First Name"),
            lastname: Yup.string().required("Please Enter Last Name"),
            email: Yup.string().email().required("Please Enter Email"),
            mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be valid')
                .min(10, 'Mobile number must be at least 10 digits')
                .max(10, 'Mobile number cannot be longer than 10 digits')
                .required("Please Enter Mobile number"),
            password: Yup.string().required("Please Enter Password"),
            isTermsAgreed: Yup.boolean().oneOf([true], 'Must agree to the terms of service and privacy policy')
                .required('Must agree to the terms of service and privacy policy')
        }),
        onSubmit: async (values) => {
            const { data: apiResponse } = await nonAuthpostAxios({
                'url': `${process.env.REACT_APP_BACKENDURL}api/register`,
                'data': {
                    firstname: values.firstname,
                    lastname: values.lastname,
                    emailid: values.email,
                    mobileno: values.mobile,
                    userpassw: values.password,
                    outvalue: 0
                }
            });
            if (apiResponse.flag === 1) {
                navigate('/login');
                toast.success(apiResponse.message);
            } else {
                toast.error(apiResponse.message);
            }
        }
    });


    return (
        <>
            <Grid className="second-section">
                <Grid item md={12}>
                    <Grid className="outer-box-style1">
                        <Typography sx={{ fontSize: 18, color: '#32393a', fontWeight: 500, }} >
                            Welcome back,
                        </Typography>
                        <Typography sx={{ fontSize: 24, color: '#32393a', fontWeight: 500, }}>
                            Register with us!
                        </Typography>
                        <form onSubmit={registerForm.handleSubmit}>
                            <Grid container>
                                <Grid item md={6} sx={{ mt: 2 }}>
                                    <Grid className="mb-1">
                                        <TextField size="small" label="First Name" name="firstname" onChange={registerForm.handleChange} value={registerForm.values.firstname} variant="outlined" sx={{ mr: 1 }} />
                                        {(registerForm.errors.firstname) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{registerForm.errors.firstname}</Typography>) : (null)}
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sx={{ mt: 2 }}>
                                    <Grid className="mb-1">
                                        <TextField size="small" label="Last Name" name="lastname" onChange={registerForm.handleChange} value={registerForm.values.lastname} variant="outlined" />
                                        {(registerForm.errors.lastname) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{registerForm.errors.lastname}</Typography>) : (null)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="mb-2 mt-3">
                                <TextField type="email" size="small" label="E-Mail" name="email" onChange={registerForm.handleChange} value={registerForm.values.email} variant="outlined" fullWidth />
                                {(registerForm.errors.email) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{registerForm.errors.email}</Typography>) : (null)}
                            </Grid>
                            <Grid className="mb-2 mt-3">
                                <TextField type="text" size="small" label="Mobile" name="mobile" onChange={registerForm.handleChange} value={registerForm.values.mobile} variant="outlined" fullWidth />
                                {(registerForm.errors.mobile) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{registerForm.errors.mobile}</Typography>) : (null)}
                            </Grid>
                            <Grid className="mb-2 mt-4">
                                <TextField type="password" size="small" label="Password" name="password" onChange={registerForm.handleChange} value={registerForm.values.password} variant="outlined" fullWidth />
                                {(registerForm.errors.password) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{registerForm.errors.password}</Typography>) : (null)}
                            </Grid>
                            <Grid className="mt-1 mb-2" sx={{ position: "relative" }}>
                                <FormControlLabel control={<Checkbox />} name='isTermsAgreed' onChange={registerForm.handleChange} checked={registerForm.values.isTermsAgreed} />
                                <Grid className="agree-style">
                                    I agree to all the &nbsp;
                                    <Link to="/" style={linkstyle}>terms of service </Link> and
                                    <Link to="/" style={linkstyle}> privacy policy</Link>
                                    {(registerForm.errors.isTermsAgreed) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{registerForm.errors.isTermsAgreed}</Typography>) : (null)}
                                </Grid>
                            </Grid>
                            <Grid className="mt-2 mb-3">
                                <Button variant="contained" type='submit' fullWidth sx={{ backgroundColor: '#32393a', borderRadius: 1.3, padding: 1.5 }}>Sign up</Button>
                            </Grid>
                            <Grid sx={{ textAlign: 'center' }}>
                                <Typography sx={{ color: '#858687', fontSize: 13, }}>Already have an account ?
                                    <Link to="/login" style={{ color: '#32393a', fontSize: 13, fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px' }}>
                                        Sign in </Link>
                                </Typography>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Register