import { Button, Card, CardContent, Container, Grid, styled, } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Search from '../Images/search-icon.png';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import { authpostAxios } from "../Utilities/commonAxios";
import moment from 'moment';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import _ from 'lodash';
import { CopyBlock, atomOneDark } from "react-code-blocks";
import { useDispatch } from "react-redux";

const Appname = styled(Typography)(() => ({
    color: '#2f3943', fontSize: 14, fontWeight: 500,
}));
const FinanceCash = styled(Typography)(() => ({
    color: '#5a6365', fontSize: 14, fontWeight: 400,
}))


const Index = () => {
    const dispatch = useDispatch()
    localStorage.removeItem('AppConnectStatus')
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);

    const [getcreateapp, setgetcreateapp] = useState([]);
    const getcreateappFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-createapp`,
                'data': ''
            }, dispatch).then(getcreateappResponse => setgetcreateapp(getcreateappResponse.data?.data))
    }
    useEffect(() => {
        getcreateappFromAPI();
    }, []);
    return (
        <>
            <Container sx={{ mb: 4 }}>
                <Grid container>
                    <Grid item md={10}>
                        <Typography sx={{ color: '#32393a', fontSize: 20, fontWeight: 500 }}>
                            Dashboard
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#32393a', fontSize: 14, }}>
                            View how your apps are performing and manage them directly from this dashboard
                        </Typography>
                    </Grid>
                    <Grid item md={2} sx={{ marginTop: 2, textAlign: "end", }}>
                        <Button variant="contained" sx={{ background: '#16a6fb', textTransform: 'capitalize' }} onClick={() => { navigate('/create-app') }}><AddIcon style={{ fontSize: '20px' }} /> Create App</Button>
                    </Grid>
                </Grid>
                {getcreateapp && getcreateapp.map((app_details, index) => (
                    <Card sx={{ mt: 2, pb: 0, boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset' }} variant="outlined" key={index}>
                        <CardContent sx={{ mb: 0 }}>
                            <Grid container>
                                <Grid item md={2} component={Link} to={((app_details.is_wb_account_connect === 1 && app_details.status === 1) ? (`/app-view/${btoa(app_details.app_id)}`) : (null))} style={{ textDecoration: 'none' }}>
                                    <Appname>
                                        App name
                                    </Appname>
                                    <FinanceCash sx={{ mt: 1 }}>
                                        {app_details.app_name}
                                    </FinanceCash>
                                    {(app_details.is_wb_account_connect === 1 && app_details.status === 1) && (
                                        <FinanceCash style={{ color: '#3097d5',marginTop:'5px' }}>
                                            <strong>Click here</strong>
                                        </FinanceCash>
                                    )}
                                </Grid>
                                <Grid item md={2}>
                                    <Appname>
                                        App Type
                                    </Appname>
                                    <FinanceCash sx={{ mt: 1 }}>
                                        {app_details.type_name}
                                    </FinanceCash>
                                </Grid>
                                <Grid item md={2}>
                                    <Appname>
                                        Messaging Limit:
                                    </Appname>
                                    <FinanceCash sx={{ mt: 1 }}>
                                        NA
                                    </FinanceCash>
                                </Grid>
                                <Grid item md={2}>
                                    <Appname>
                                        Date & Time:
                                    </Appname>
                                    <FinanceCash sx={{ mt: 1 }}>
                                        {moment(app_details.created_at).format('DD-MM-YYYY HH:MM:SS A')}
                                    </FinanceCash>
                                </Grid>
                                <Grid item md={3}>
                                    <Appname>
                                        App Code
                                    </Appname>
                                    <FinanceCash sx={{ mt: 1 }}>
                                        <Appname className='codeBlockView'>
                                            <CopyBlock language="javascript" text={app_details.app_code} theme={atomOneDark} showLineNumbers={false} />
                                        </Appname>
                                    </FinanceCash>
                                </Grid>
                                <Grid item md={1} sx={{ textAlign: 'end' }}>
                                    <Appname sx={{ mb: 1 }}>Status:</Appname>
                                    {app_details.is_wb_account_connect === 0 ? (
                                        <>
                                            <Link to={`/app-connect/${btoa(app_details.app_id)}`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="info" size="small" sx={{ textTransform: 'capitalize' }}>Go Live</Button>
                                            </Link>
                                        </>
                                    ) : (
                                        <FinanceCash sx={{ mt: 1,color: '#3097d5' }}>
                                            <strong>Connected</strong>
                                        </FinanceCash>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
            </Container >
        </>
    )
}

export default Index