import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const NonAuthMiddleware = (props) => {
  let isAuthenticated = useSelector((state) => state.auth);
  if (
    (Object.keys(isAuthenticated?.postItems).length > 0 ||
      isAuthenticated?.postItems.length > 0) &&
    isAuthenticated.postItems?.data?.userDetails &&
    isAuthenticated.postItems?.data?.userToken &&
    isAuthenticated.userType === btoa("user")
  ) {
    return (
      <Navigate
        to={{ pathname: "/dashboard", state: { from: props.location } }}
      />
    );
  } else {
    return <>{props.children}</>;
  }
};

const AuthMiddleware = (props) => {
  let isAuthenticated = useSelector((state) => state.auth);
  if (isAuthenticated.isSessionExpired) {
    toast.error("Session has expired!");
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  } else if (isAuthenticated.isLogout) {
    toast.success("Logout Successfully");
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  } else if (
    (Object.keys(isAuthenticated?.postItems).length > 0 ||
      isAuthenticated?.postItems.length > 0) &&
    isAuthenticated.isLogin &&
    isAuthenticated.postItems?.data?.userDetails &&
    isAuthenticated.postItems?.data?.userToken &&
    isAuthenticated.userType === btoa("user")
  ) {
    return <>{props.children}</>;
  } else {
    toast.error("Unauthorized access!");
    localStorage.clear()
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
};

const AuthConnectMiddleware = (props) => {
  if (
    localStorage.getItem("AppConnectStatus") &&
    atob(localStorage.getItem("AppConnectStatus")) === "0"
  ) {
    toast.error("Unauthorized access - App is not in live mode!");
    return (
      <Navigate
        to={{ pathname: "/dashboard", state: { from: props.location } }}
      />
    );
  } else {
    return <>{props.children}</>;
  }
};

const AdminNonAuthMiddleware = (props) => {
  let isAuthenticated = useSelector((state) => state.auth);
  if (
    isAuthenticated.postItems?.data?.userDetails &&
    isAuthenticated.postItems?.data?.userToken &&
    isAuthenticated.userType === btoa("admin")
  ) {
    return (
      <Navigate
        to={{ pathname: "/admin/dashboard", state: { from: props.location } }}
      />
    );
  } else {
    return <>{props.children}</>;
  }
};

const AdminAuthMiddleware = (props) => {
  let isAuthenticated = useSelector((state) => state.auth);
  if (typeof isAuthenticated?.postItems.length !== "undefined") {
    toast.error("Unauthorized access!");
    localStorage.clear()
    return (
      <Navigate to={{ pathname: "/admin", state: { from: props.location } }} />
    );
  } else if (isAuthenticated.isSessionExpired) {
    toast.error("Session has expired!");
    return (
      <Navigate to={{ pathname: "/admin", state: { from: props.location } }} />
    );
  } else if (isAuthenticated.isLogout) {
    toast.success("Logout Successfully");
    return (
      <Navigate to={{ pathname: "/admin", state: { from: props.location } }} />
    );
  } else if (
    typeof isAuthenticated?.postItems.length !== "undefined" &&
    isAuthenticated.isLogin &&
    isAuthenticated.postItems?.data?.userDetails &&
    isAuthenticated.postItems?.data?.userToken &&
    isAuthenticated.userType === btoa("admin")
  ) {
    return <>{props.children}</>;
  } else {
    toast.error("Unauthorized access!");
    localStorage.clear()
    return (
      <Navigate to={{ pathname: "/admin", state: { from: props.location } }} />
    );
  }
};

export {
  NonAuthMiddleware,
  AuthMiddleware,
  AuthConnectMiddleware,
  AdminNonAuthMiddleware,
  AdminAuthMiddleware,
};
