import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nonAuthpostAxios,authpostAxios } from "../Utilities/commonAxios";

const initialState = {
  postItems: [],
  isSessionExpired: false,
  isLogin: false,
  isLogout: false,
  userType: '',
  walletAmount: 0
}

export const authLogin = createAsyncThunk('auth/authLogin', async (inputData, thunkAPI) => {
  try {
    const { data: loginResponse } = await nonAuthpostAxios({
      'url': `${process.env.REACT_APP_BACKENDURL}api/login`,
      'data': inputData
    });
    return loginResponse;
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.message })
  }
})

export const getWalletAmount = createAsyncThunk('auth/getWalletAmount', async (inputData, thunkAPI) => {
  try {
    const { data: walletResponse } = await authpostAxios({
      'url': `${process.env.REACT_APP_BACKENDURL}api/get-walletamount`,
      'data': {}
    });
    return walletResponse;
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.message })
  }
})

export const adminAuthLogin = createAsyncThunk('auth/adminAuthLogin', async (inputData, thunkAPI) => {
  try {
    const { data: loginResponse } = await nonAuthpostAxios({
      'url': `${process.env.REACT_APP_BACKENDURL}api/admin/login`,
      'data': inputData
    });
    return loginResponse;
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.message })
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    manageUserSession: (state, { payload }) => {
      if (payload === 'expired' || payload === 'logout') state.postItems = []
      state.isSessionExpired = (payload === 'expired') ? (true) : (false)
      state.isLogin = false
      state.isLogout = (payload === 'logout') ? (true) : (false)
      state.userType = ''
      state.walletAmount = 0
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state, action) => {
        state.postItems = []
        state.isSessionExpired = false
        state.isLogin = false
        state.isLogout = false
        state.userType = ''
        state.walletAmount = 0
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.postItems = action.payload
        state.isSessionExpired = false
        state.isLogin = true
        state.isLogout = false
        state.userType = btoa('user')
        state.walletAmount = action.payload?.data?.userDetails?.credit_balance
      })
      .addCase(authLogin.rejected, (state, action) => {
        state.postItems = []
        state.isSessionExpired = false
        state.isLogin = false
        state.isLogout = false
        state.userType = ''
        state.walletAmount = 0
      })
      .addCase(getWalletAmount.fulfilled, (state, action) => {
        state.walletAmount = action.payload?.data?.credit_balance
      })
      .addCase(adminAuthLogin.pending, (state, action) => {
        state.postItems = []
        state.isSessionExpired = false
        state.isLogin = false
        state.isLogout = false
        state.userType = ''
      })
      .addCase(adminAuthLogin.fulfilled, (state, action) => {
        state.postItems = action.payload
        state.isSessionExpired = false
        state.isLogin = true
        state.isLogout = false
        state.userType = btoa('admin')
      })
      .addCase(adminAuthLogin.rejected, (state, action) => {
        state.postItems = []
        state.isSessionExpired = false
        state.isLogin = false
        state.isLogout = false
        state.userType = ''
      })
  },
})

export const { manageUserSession } = authSlice.actions
export default authSlice.reducer