import { Grid,Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

export const VariableDropdown = ({ templateVariables,handleVariableAddition,field=null }) => {
  return (
    <>
      <Grid container>
        <Grid md={8}></Grid>
        <Grid item md={4} sx={{ textAlign: "right" }}>
          <FormControl size="small" fullWidth>
            <InputLabel
              sx={{ color: "#92a2b1", fontSize: 15, fontWeight: 400 }}
            >
              Variable
            </InputLabel>
            <Select
              label="Variable"
              sx={{
                color: "#000000de",
                fontSize: 13,
                fontWeight: 500,
                borderRadius: "50px",
              }}
              onChange={(e)=>handleVariableAddition(e.target.value,field)}
            >
              {templateVariables.map((variable,index)=>(
                <MenuItem key={index} value={variable}>{variable}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
