import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Grid,
  Button,
  TextField,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Delete from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const suggestedActionLists = {
  1: "Simple Reply",
  2: "Open URL Action",
  3: "Dial Action",
  4: "View Location (Lat/Long)",
  5: "View Location (Query)",
  6: "Share Location",
};

export function SuggestedActionMenu({ formik }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleToggleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleToggleClose = (actionId = null) => {
    if (formik.values.selectedSuggestedActions.length < 3) {
      if (actionId !== 0) {
        formik.setFieldValue("selectedSuggestedActions", [
          ...formik.values.selectedSuggestedActions,
          actionId,
        ]);
      }
    } else {
      toast.error("Only 3 Suggestions are allowed!");
    }
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleToggleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          justifyContent: "space-between",
          fontSize: "14px",
          textTransform: "capitalize",
          width: "100%",
        }}
      >
        Add Suggested Action
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleToggleClose(0)}
      >
        {Object.keys(suggestedActionLists).map((key) => (
          <>
            <MenuItem onClick={() => handleToggleClose(key)} disableRipple>
              {suggestedActionLists[key]}
            </MenuItem>
          </>
        ))}
      </StyledMenu>
    </div>
  );
}

export function SuggestedActionMenuDiv({ actionId, index,handleDeleteSuggestedAction }) {
  return (
    <Grid item md={12} key={index} sx={{ mb: "10px" }}>
      <Accordion>
        <AccordionSummary
          sx={{
            fontSize: "15px",
            fontWeight: "600",
            color: "#000000ba",
          }}
          expandIcon={<ExpandMore />}
          aria-controls={`panel${actionId}${index}-content`}
          id={`panel${actionId}${index}-header`}
        >
          <Grid container item spacing={1}>
            <Grid item md="10">
              {suggestedActionLists[actionId]} (Reply : {Number(index) + 1})
            </Grid>
            <Grid item md="2" sx={{}}>
                <Delete
                  sx={{
                    color: "red",
                    fontSize: "20px",
                  }}
                  onClick={()=>handleDeleteSuggestedAction(actionId)}
                />
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider sx={{ border: "1px solid #c8c8c8" }} />
        <AccordionDetails>
          <Grid container item spacing={2}>
            <Grid md={6} item sx={{}}>
              <TextField             
                label="Suggested Text"
                id="outlined-size-small"
                defaultValue=""
                size="small"
                fullWidth
                InputLabelProps={{
                  sx: {
                    color: "#92a2b1",
                    fontSize: 15,
                    fontWeight: 400,
                  },
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: 13,
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid md={6} item sx={{}}>
              <TextField
                label="Suggested Postback"
                id="outlined-size-small"
                defaultValue=""
                size="small"
                fullWidth
                InputLabelProps={{
                  sx: {
                    color: "#92a2b1",
                    fontSize: 15,
                    fontWeight: 400,
                  },
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: 13,
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            {actionId === "2" && (
              <Grid md={6} item sx={{}}>
                <TextField
                  label="URL To Open"
                  id="outlined-size-small"
                  defaultValue=""
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    sx: {
                      color: "#92a2b1",
                      fontSize: 15,
                      fontWeight: 400,
                    },
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: 13,
                      fontWeight: 500,
                    },
                  }}
                />
              </Grid>
            )}
            {actionId === "3" && (
              <Grid md={12} item sx={{}}>
                <TextField
                  label="Mobile Number (with Country Code)"
                  id="outlined-size-small"
                  defaultValue=""
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    sx: {
                      color: "#92a2b1",
                      fontSize: 15,
                      fontWeight: 400,
                    },
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: 13,
                      fontWeight: 500,
                    },
                  }}
                />
              </Grid>
            )}
            {actionId === "4" && (
              <>
                <Grid md={6} item sx={{}}>
                  <TextField
                    label="Latitude"
                    id="outlined-size-small"
                    defaultValue=""
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      sx: {
                        color: "#92a2b1",
                        fontSize: 15,
                        fontWeight: 400,
                      },
                    }}
                    inputProps={{
                      sx: {
                        color: "#000000de",
                        fontSize: 13,
                        fontWeight: 500,
                      },
                    }}
                  />
                </Grid>
                <Grid md={6} item sx={{}}>
                  <TextField
                    label="Longitude"
                    id="outlined-size-small"
                    defaultValue=""
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      sx: {
                        color: "#92a2b1",
                        fontSize: 15,
                        fontWeight: 400,
                      },
                    }}
                    inputProps={{
                      sx: {
                        color: "#000000de",
                        fontSize: 13,
                        fontWeight: 500,
                      },
                    }}
                  />
                </Grid>
                <Grid md={6} item sx={{}}>
                  <TextField
                    label="Label"
                    id="outlined-size-small"
                    defaultValue=""
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      sx: {
                        color: "#92a2b1",
                        fontSize: 15,
                        fontWeight: 400,
                      },
                    }}
                    inputProps={{
                      sx: {
                        color: "#000000de",
                        fontSize: 13,
                        fontWeight: 500,
                      },
                    }}
                  />
                </Grid>
              </>
            )}
            {actionId === "5" && (
              <>
                <Grid md={6} item sx={{}}>
                  <TextField
                    label="Location"
                    id="outlined-size-small"
                    defaultValue=""
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      sx: {
                        color: "#92a2b1",
                        fontSize: 15,
                        fontWeight: 400,
                      },
                    }}
                    inputProps={{
                      sx: {
                        color: "#000000de",
                        fontSize: 13,
                        fontWeight: 500,
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
