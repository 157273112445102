import {
    Container, FormControl, Grid, InputLabel, MenuItem, Select,
    Typography, CardContent, Card, Button, Divider, TextField
} from '@mui/material';
import * as React from 'react';
import Wallet from '@mui/icons-material/AccountBalanceWalletOutlined';



const Topuprequest = () => {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Container>
                <Grid sx={{ mt: 5, mb: 5 }}>
                    <Grid container>
                        <Grid item md={6}>
                            <FormControl size='small' style={{ width: 300 }}>
                                <InputLabel id="demo-simple-select-label" sx={{ color: '#92a2b1', size: 12, }}>Request with</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Request with"
                                    onChange={handleChange}
                                    sx={{ color: '#92a2b1' }}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <Grid sx={{ border: 'solid 1px #e3e3e3', padding: '5px 2px 8px 15px', borderRadius: 1 }}>
                                <Grid container>
                                    <Grid item md={4}>
                                        <Wallet /> <span sx={{ color: '#32393a', fontSize: 14, fontWeight: 500 }}>Prepaid Wallet</span>
                                    </Grid>
                                    <Grid item md={4}>
                                        <span style={{ color: '#92a2b1', fontSize: 13, }}>Trader name:</span> <span style={{ color: '#32393a', fontSize: 14, fontWeight: 500 }}>TEST </span>
                                    </Grid>
                                    <Grid item md={4}>
                                        <span style={{ color: '#92a2b1', fontSize: 13, }}>Trade Balance:</span> <span style={{ color: '#32393a', fontSize: 14, fontWeight: 500 }}>560,726.44 </span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Card sx={{ border: '1px solid #e3e3e3' }}>
                        <Grid sx={{ p: '10px 15px', background: '#f9f9f9' }}>
                            <Typography sx={{ color: '#32393a', fontSize: 16, fontWeight: 600 }}>
                                Net Transfer
                            </Typography>
                        </Grid>
                        <Divider />
                        <CardContent>
                            <Grid container>
                                <Grid item md={6}>
                                    <Grid sx={{ mt: 2, mb: 3, mr: 2 }}>
                                        <TextField size="small" label="Net Transfer ID" name="Net Transfer ID" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid sx={{ mt: 2, mb: 3, mr: 2 }}>
                                        <FormControl size='small' fullWidth>
                                            <InputLabel id="demo-simple-select-label">Bank name</InputLabel>
                                            <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                                value={age}
                                                label="Request with"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ mt: 2, mb: 3, mr: 2 }}>
                                        <TextField size="small" label="Account holder name" name="Account holder name" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid sx={{ mt: 2, mb: 3, mr: 2 }}>
                                        <TextField size="small" label="Amount (Rs.)" name="Amount (Rs.)" variant="outlined" fullWidth />
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid sx={{ mt: 2, mb: 3, }}>
                                        <TextField size="small" label="Deposited Bank" name="Deposited Bank" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid sx={{ mt: 2, mb: 3,}}>
                                        <FormControl size='small' fullWidth>
                                            <InputLabel id="demo-simple-select-label">Bank name</InputLabel>
                                            <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                                value={age}
                                                label="Request with"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ mt: 2, mb: 3, }}>
                                        <TextField size="small" label="Account number" name="Account number" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid sx={{ mt: 2, mb: 3, }}>
                                        <TextField size="small" label="Remarks" name="Remarks" variant="outlined" fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item md={12}>
                                  <Button variant='outlined' sx={{color:'#92a2b1', border:'solid 1px #92a2b1', mr:2}}>Cancel</Button>
                                  <Button variant='contained' sx={{color:'#fff', background:'#16a6fb', border:'solid 1px #16a6fb', padding:'6px 26px'}}>Save</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
        </>
    )

}

export default Topuprequest