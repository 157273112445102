import { Button, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { authpostAxios } from "../Utilities/commonAxios";
import { RotatingLines, ThreeDots } from 'react-loader-spinner'
import Success from '@mui/icons-material/CheckCircleOutline';
import Faild from '@mui/icons-material/CancelOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getWalletAmount } from "../Reduxstore/authSlice";

const PaymentResponse = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const orderId = new URLSearchParams(location.search).get('order_id');
    const [isfetchedStatus, setIsfetchedStatus] = useState(true)
    const [orderDetails, setOrderDetails] = useState([])
    const getOrderDetailsAPI = async () => {
        const { data: orderResponse } = await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/payment/order-details`,
                'data': { order_id: orderId }
            }, dispatch)
        if (orderResponse?.flag !== 1) {
            toast.error('Payment session has expired!')
            navigate('/my-wallet')
        } else {
            await dispatch(getWalletAmount())
            setIsfetchedStatus(false)
            setOrderDetails(orderResponse.data)
        }
    }
    useEffect(() => {
        getOrderDetailsAPI()
    }, [])
    return (
        <>
            <Container sx={{ mb: 4, }}>
                {(!isfetchedStatus && orderDetails) ? (
                    <>
                        <Grid container>
                            <Grid item md={4}>
                            </Grid>
                            <Grid item md={4}>
                                <Card sx={{ mt: 2, pb: 0, padding: '15px 10px 15px 20px', boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset' }} variant="outlined">
                                    {(orderDetails.payment_status === 'success') ? (
                                        <Grid item md={12} sx={{ textAlign: 'center', mb: 3 }}>
                                            <Success sx={{ color: 'green', fontSize: '30px', marginBottom: '8px' }} />
                                            <Typography sx={{ color: 'green', fontSize: '16px', fontWeight: 500 }}>
                                                Payment Success
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item md={12} sx={{ textAlign: 'center', mb: 3 }}>
                                            <Faild sx={{ color: 'red', fontSize: '30px', marginBottom: '8px' }} />
                                            <Typography sx={{ color: 'red', fontSize: '16px', fontWeight: 500 }}>
                                                Payment Failed
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid sx={{ mb: 0 }}>
                                        <Grid container item spacing={1} sx={{ marginBottom: '5px' }}>
                                            <Grid item md={4}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Order Id</Typography>
                                            </Grid>
                                            <Grid item md={1}>:</Grid>
                                            <Grid item md={7}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#4e4c4cde', textTransform: 'capitalize', }}> {orderDetails.order_id}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} sx={{ marginBottom: '5px' }}>
                                            <Grid item md={4}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Payment Ref No</Typography>
                                            </Grid>
                                            <Grid item md={1}>:</Grid>
                                            <Grid item md={7}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#4e4c4cde', textTransform: 'capitalize', }}>  {orderDetails.cf_payment_id}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} sx={{ marginBottom: '5px' }}>
                                            <Grid item md={4}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Payment Amount</Typography>
                                            </Grid>
                                            <Grid item md={1}>:</Grid>
                                            <Grid item md={7}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#4e4c4cde', textTransform: 'capitalize', }}>  {orderDetails.payment_amount}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} sx={{ marginBottom: '5px' }}>
                                            <Grid item md={4}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Payment Mode</Typography>
                                            </Grid>
                                            <Grid item md={1}>:</Grid>
                                            <Grid item md={7}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#4e4c4cde', textTransform: 'capitalize', }}>{orderDetails.payment_group}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} sx={{ marginBottom: '5px' }}>
                                            <Grid item md={4}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Payment Status</Typography>
                                            </Grid>
                                            <Grid item md={1}>:</Grid>
                                            <Grid item md={7}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#4e4c4cde', textTransform: 'capitalize', }}>{orderDetails.payment_status}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <Grid item md={4}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}> Payment Time</Typography>
                                            </Grid>
                                            <Grid item md={1}>:</Grid>
                                            <Grid item md={7}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#4e4c4cde', textTransform: 'capitalize', }}>{orderDetails.payment_time}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Grid item md={12} sx={{ mt: 2, textAlign: 'center' }}>
                                    <Button variant="contained" sx={{
                                        font: '14px', fontWeight: 400, textTransform: 'capitalize', lineHeight: 'unset', '&:.css-1ck0sme-MuiButtonBase-root-MuiButton-root:hover': {
                                            backgroundColor: "#fff",
                                        },
                                    }} component={Link} to="/my-wallet"><HomeOutlinedIcon /> My Wallet</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (<ThreeDots
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperClass=""
                    wrapperStyle={{ justifyContent: "center" }} />)}
            </Container>
        </>
    )
}

export default PaymentResponse