
import { Button, Card, CardContent, Container, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Add, RemoveRedEyeOutlined, Sync } from '@mui/icons-material';
import moment from 'moment';
import { authpostAxios } from "../../Utilities/commonAxios";
// toast
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner'
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";

const ViewRCSTemplates = (props) => {
    const dispatch = useDispatch()
    const { AppId } = props;
    // fetch template status
    const [isfetchedStatus, setIsfetchedStatus] = useState(false)
    const fetchTemplateStatus = async () => {
        setIsfetchedStatus(true)
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/templateApproval/${AppId}`,
                'data': ''
            },dispatch).then(response => {
                setIsfetchedStatus(false)
                if (response.data?.flag === 1) {
                    gettemplatelistAPI()
                    toast.success(response.data.message)
                }
            })
    }
    // template list
    const [gettemplatelists, setGettemplatelists] = useState([]);
    const [tableData, setTableData] = useState([]);
    const gettemplatelistAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-template/${AppId}`,
                'data': ''
            },dispatch).then(gettemplateResponse => setGettemplatelists(gettemplateResponse.data?.data))
    }
    useEffect(() => {
        gettemplatelistAPI()
    }, [])
    useEffect(() => {
        let newTableData = [];
        gettemplatelists && gettemplatelists.map((gettemplatelist, index) => {
            newTableData.push([gettemplatelist.app_name, gettemplatelist.template_name, gettemplatelist.category_name, ((gettemplatelist.created_at) ? (moment(gettemplatelist.created_at).format('DD-MM-YYYY HH:MM:SS A')) : ('')), gettemplatelist.whatsapp_approval_status, gettemplatelist.template_code,gettemplatelist.template_language_code])
        })
        setTableData(newTableData)
    }, [gettemplatelists])
    // table-options
    const [searchBtn, setSearchBtn] = useState(true);
    const options = {
        search: searchBtn,
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none'
    }
    // table-columns
    const columns = [
        "App Name",
        {
            name: "Template",
            options: {
                filterOptions: { fullWidth: true },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Typography sx={{fontSize: 15, fontWeight: 400}}>
                                {`${tableMeta.rowData[1]}`}
                            </Typography>
                            <Typography sx={{fontSize: 15, fontWeight: 400}}>
                            <strong>code:</strong>{` ${tableMeta.rowData[5]}`}
                            </Typography>
                            <Typography sx={{fontSize: 15, fontWeight: 400}}>
                            <strong>Language:</strong>{` ${tableMeta.rowData[6]}`}
                            </Typography>
                        </>
                    )
                }
            }
        },
        "Category",
        "Date/Time",
        "Approval Status",
        {
            name: "TemplateCode",
            options: {
                display: false
            }
        },
        {
            name: "TemplateLanguageCode",
            options: {
                display: false
            }
        }
    ];

    return (
        <>
            <Grid item md={12}>
                <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                    {/* {!isfetchedStatus && (
                        <Button variant="contained" sx={{
                            background: '#00BCD4',
                            textTransform: 'capitalize', pb: 1, pt: 1, mb: 1, mr: 1, '&:hover': {
                                backgroundColor: "#00BCD4", color: '#fff',
                            },
                        }} onClick={fetchTemplateStatus}>
                            <Sync style={{ fontSize: '19px' }} />Fetch Template Status
                        </Button>
                    )} */}
                    <Button component={Link} to={`/create-rcs-template/${AppId}`} variant="contained" sx={{
                        background: '#16a6fb',
                        textTransform: 'capitalize', pb: 1, pt: 1, mb: 1, '&:hover': {
                            backgroundColor: "#16a6fb", color: '#fff',
                        },
                    }}>
                        <Add style={{ fontSize: '19px' }} /> Create Template
                    </Button>
                </Grid>
            </Grid>
            {(!isfetchedStatus) ? (
                <>
                    <Grid item md={12} sx={{ mt: 2 }}>
                        <MUIDataTable
                            columns={columns}
                            data={tableData}
                            options={options} 
                            className="custom-datatable-class" />
                    </Grid>
                </>
            ) : (
                <ThreeDots
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperClass=""
                    wrapperStyle={{ justifyContent: "center" }} />
            )}
        </>
    )
}

export default ViewRCSTemplates