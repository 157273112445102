
import { Button, Grid, TextField } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import { authpostAxios } from "../../Utilities/commonAxios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

const UserWebhook = (props) => {
    const dispatch = useDispatch()
    const [appWebhookURLDetails, setAppWebhookURLDetails] = useState('');
    const getAppwebhookurlAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-appwebhookurl`,
                'data': { appId: atob(props.AppId) }
            },dispatch).then(response => setAppWebhookURLDetails(response.data?.data))
    }
    useEffect(() => {
        getAppwebhookurlAPI()
    }, [])
    const submitWebhookForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            webhookUrl: appWebhookURLDetails
        },
        validationSchema: Yup.object({
            webhookUrl: Yup.string().url('Please enter valid URL').required('Please enter valid URL')
        }),
        onSubmit: async (values) => {
            const { data: updAppwebhookurlResponse } = await authpostAxios(
                {
                    'url': `${process.env.REACT_APP_BACKENDURL}api/upd-appwebhookurl`,
                    'data': { callBackUrl: values.webhookUrl, appId: atob(props.AppId) }
                },dispatch)
                if(updAppwebhookurlResponse.flag ===1) toast.success(updAppwebhookurlResponse.message)
                else toast.error(updAppwebhookurlResponse.message)
        }
    })
    return (
        <>
            <form onSubmit={submitWebhookForm.handleSubmit}>
                <Grid item container spacing={2}>
                    <Grid item md={11}>
                        <TextField name="webhookUrl" id="outlined-basic" label="Web URL" variant="outlined" fullWidth size="small" autoComplete="off" onChange={submitWebhookForm.handleChange} value={submitWebhookForm.values.webhookUrl} />
                        <Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitWebhookForm.errors.webhookUrl}</Typography>
                    </Grid>
                    <Grid item md={1} sx={{ textAlign: 'end' }}>
                        <Button type="submit" variant="contained" sx={{ textTransform: 'capitalize' }}>Update</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default UserWebhook