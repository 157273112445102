import { Button, Dialog, DialogTitle, DialogContent, IconButton, DialogActions } from "@mui/material";
import { CopyBlock, atomOneDark } from "react-code-blocks";
import { Close } from '@mui/icons-material';

export const CodeBlockModal = ({ handlePopupClose, isOpen, dialogTitle, popupData }) => {
    return (
        <Dialog
            onClose={handlePopupClose}
            aria-labelledby="scroll-dialog-title"
            open={isOpen}
            fullWidth scroll={'paper'}
        >
            <DialogTitle id="scroll-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handlePopupClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            <DialogContent dividers>
                <CopyBlock language="javascript" text={JSON.stringify(popupData, null, 2)} theme={atomOneDark} showLineNumbers={false} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePopupClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}