import { Bolt } from "@mui/icons-material"
import { Container, Typography, Grid } from "@mui/material"


const privacyStyle = {
    marginTop: '115px',
}


const Terms = () => {
    return (
        <>
            <Grid style={privacyStyle}>
                <Container>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography sx={{ fontSize: '25px', fontWeight: 600, textAlign: 'center' }}>Terms and Conditions </Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ marginTop: '30px', fontSize: '20px', fontWeight: 500, mb: 2 }}>Interpretation and Definitions</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Interpretation</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1 }}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Definitions</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1 }}>For the purposes of these Terms and Conditions:</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Affiliate </strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Country  </strong> refers to: Tamil Nadu, India</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Company   </strong>  (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Edupoint Solutions Private Limited,30,Palat Sankaran Road,Venkateswara Castle,S2 Second Floor. A, Mahalingapuram, Chennai, Tamil Nadu 600034.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Device   </strong>  means any device that can access the Service such as a computer, a cellphone or a digital tablet.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Service    </strong>  refers to the Website.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Terms and Conditions </strong>  (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Third-party Social Media Service </strong>  means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>Website  </strong> refers to cares, accessible from http://careshub.in/</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, ml: 4 }}><strong sx={{ fontWeight: Bolt }}>You   </strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Google API Services Usage Disclosure
                            </Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>careshub's use of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements..</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Limited Use</Typography>
                            <Typography sx={{lineHeight: '30px', mt: 1, }}>Do not allow humans to read the user's data unless you have obtained the user's affirmative agreement to view specific messages, files, or other data..</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>Do not use or transfer the data for serving ads, including retargeting, personalized, or interest-based advertising.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>Limit your use of data to providing or improving user-facing features that are prominent in the requesting application's user interface. All other uses of the data are prohibited.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Acknowledgment</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Links to Other Websites</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Termination</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>Upon termination, Your right to use the Service will cease immediately.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>"AS IS" and "AS AVAILABLE" Disclaimer</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Governing Law</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</Typography>
                            <Typography sx={{ marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Disputes Resolution</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</Typography>
                            <Typography sx={{ lineHeight: '30px', mt: 1,fontWeight: 500 }}>Severability and Waiver</Typography>
                            <Typography sx={{ lineHeight: '30px', mt: 1,fontWeight: 500 }}>Severabilityr</Typography>
                            <Typography sx={{ color: '#628498', lineHeight: '30px', mt: 1, }}>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</Typography>
                            <Typography sx={{ lineHeight: '30px', mt: 1,fontWeight: 500 }}>Waiver</Typography>
                            <Typography sx={{ lineHeight: '30px',color: '#628498', mt: 1,fontWeight: 500 }}>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</Typography>
                            <Typography sx={{ lineHeight: '30px', mt: 1,fontWeight: 500 }}>Translation Interpretation</Typography>
                            <Typography sx={{ lineHeight: '30px',color: '#628498', mt: 1,fontWeight: 500 }}>These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</Typography>
                            <Typography sx={{ lineHeight: '30px', mt: 1,fontWeight: 500 }}>Changes to These Terms and Conditions</Typography>
                            <Typography sx={{ lineHeight: '30px',color: '#628498', mt: 1,fontWeight: 500 }}>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</Typography>
                            <Typography sx={{ lineHeight: '30px',color: '#628498', mt: 1,fontWeight: 500 }}>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</Typography>
                            <Typography sx={{ lineHeight: '30px', mt: 1,fontWeight: 500 }}>Contact Us</Typography>
                            <Typography sx={{ lineHeight: '30px',color: '#628498', mt: 1,fontWeight: 500 }}>If you have any questions about these Terms and Conditions, You can contact us:</Typography>
                            <Typography sx={{ lineHeight: '30px',color: '#628498', mt: 1,fontWeight: 500, mb:3 }}>By visiting this page on our website: http://careshub.in</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}


export default Terms