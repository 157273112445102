import * as React from 'react';
import { Container, Grid, Breadcrumbs, Typography } from "@mui/material"
import { Link } from 'react-router-dom';


const Bread = () => {
    return (
        <>
            <Grid sx={{ m: 3 }}>
                <Container>
                    <Grid item md={12}>
                        <Breadcrumbs separator="/" aria-label="breadcrumbs">
                            {['Dashboard'].map((item) => (
                                <Link key={item} className='breadcrumbs-style' to="/dashboard">
                                    {item}
                                </Link>
                            ))}
                            <Typography sx={{ color: '#32393a', fontSize: 13, fontWeight: 500 }}>WhatsApp</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Bread