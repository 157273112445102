import { Avatar, Box, Container, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../Images/login-logo.png';
import noImage from '../Images/no-image.png'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { manageUserSession } from "../Reduxstore/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { authpostAxios } from "../Utilities/commonAxios";
import { toast } from "react-toastify";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector(state => state.auth);
    let userDetails = authState?.postItems?.data?.userDetails;
    const submitLogout = async () => await dispatch(manageUserSession('logout'))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const submenuDropdown = {
        px: 2, py: 0, fontSize: 15, color: '#212529'
    }
    // initial company data
    const [userCompanyLogo, setUserCompanyLogo] = useState('');
    const getUserCompanyDetailsFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/usercompanydetails`,
                'data': ''
            }, dispatch).then(userCompanyDetailsResponse => setUserCompanyLogo(userCompanyDetailsResponse.data?.data?.logo_idproofpath))
    }
    useEffect(() => {
        getUserCompanyDetailsFromAPI()
    }, [])
    return (
        <>
            <Grid sx={{ background: '#fff', borderBottom: 1, borderColor: '#eaeff5', padding: 1, }}>
                <Container>
                    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item md={5}>
                            <Link to="/dashboard">
                                <img src={Logo} alt="Logo" />
                            </Link>
                        </Grid>
                        <Grid item md={7} sx={{ display: 'flex', justifyContent: "end", alignItems: 'center' }}>

                            <Stack
                                direction='row'
                                alignItems='center'
                                spacing={{ xs: 0.5, sm: 1.5 }}
                            >

                                <Box style={{ marginRight: 10 }}>
                                    <Link to="/my-wallet" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Grid sx={{ display: 'flex' }}>
                                            <AccountBalanceWalletOutlinedIcon style={{ fontSize: 18, color: '#32393a', }} />
                                            <Typography sx={{ color: '#32393a', fontSize: 14, fontWeight: 500, ml: 0.5 }}>
                                                My Wallet:
                                            </Typography>
                                        </Grid>
                                        <Typography level="body-sm" sx={{ fontSize: 14, fontWeight: 500, color: '#32393a', textAlign: 'right' }}><CurrencyRupeeIcon style={{ fontSize: 15, color: '#32393a' }} /> {authState?.walletAmount}</Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <Tooltip title="Myaccount">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{
                                                ml: 0,
                                                "&.MuiButtonBase-root:hover": {
                                                    bgcolor: "transparent"
                                                }
                                            }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}

                                        >
                                            <Avatar alt="Profile" src={(userCompanyLogo) ? (userCompanyLogo) : (noImage)} sx={{ borderRadius: 3, mr: 1 }} />

                                            <Grid>
                                                <Typography level="title-sm" sx={{ color: '#32393a', fontSize: 12, }}>Customer:</Typography>
                                                <Typography level="body-sm" sx={{ fontSize: 14, color: '#32393a', fontWeight: 500 }}>{userDetails?.firstname + ' ' + userDetails?.lastname}
                                                </Typography>
                                            </Grid>

                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleClose} style={submenuDropdown}>
                                        {/* <ListItemIcon sx={{ p: 0, }} style={{ minWidth: 28 }}>
                                            <Profileicon fontSize="small" />
                                        </ListItemIcon> */}
                                        My Profile
                                    </MenuItem>
                                    <MenuItem component={RouterLink} to="/companydetails">Company Details</MenuItem>
                                    <Divider />
                                    <MenuItem style={submenuDropdown} onClick={() => submitLogout()}>
                                        <ListItemIcon sx={{ p: 0, }} style={{ minWidth: 28 }}>
                                            <LoginOutlinedIcon fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Stack>

                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Header