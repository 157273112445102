import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, CardContent, Container, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { authpostAxios } from "../../Utilities/commonAxios";
import moment from "moment";
import { CopyBlock, a11yLight } from "react-code-blocks";
import { useDispatch } from "react-redux";

const Appname = styled(Typography)(() => ({
    color: '#2f3943', fontSize: 14, fontWeight: 500,
}));
const FinanceCash = styled(Typography)(() => ({
    color: '#5a6365', fontSize: 14, fontWeight: 400,
}))


function Companydetails(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

Companydetails.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const dispatch = useDispatch()
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { UserID } = useParams()
    const [appLists, setApLists] = useState([])
    const getAppListAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/admin/get-createapp`,
                'data': { "appUserId": atob(UserID) }
            }, dispatch).then(AppListResponse => setApLists(AppListResponse.data?.data))
    }
    useEffect(() => {
        getAppListAPI()
    }, [])
    return (
        <Container>
            <Grid container item spacing={2}>
                <Grid item md={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="App List" sx={{ textTransform: 'capitalize', }} {...a11yProps(0)} />
                                {/* <Tab label="Item Two" sx={{ textTransform: 'capitalize', }} {...a11yProps(1)} /> */}
                            </Tabs>
                        </Box>
                        <Companydetails value={value} index={0}>
                            {appLists && appLists.map((appList, index) => (
                                <>
                                    <Card sx={{ mt: 2, pb: 0, boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset' }} variant="outlined" key={index}
                                    >
                                        <CardContent style={{ paddingBottom: '16px' }}>
                                            <Grid container>
                                                <Grid item md={3} component={Link} to={((appList.is_wb_account_connect === 1 && appList.status === 1) ? (`/admin/templatelist/${UserID}/${btoa(appList.appid)}`) : (null))} style={{ textDecoration: 'none' }}>
                                                    <Appname>
                                                        App name
                                                    </Appname>
                                                    <FinanceCash sx={{ mt: 1, }}>
                                                        {appList.app_name}
                                                    </FinanceCash>
                                                    <Link href="#" style={{ textDecoration: 'none', fontSize: '13px' }}>
                                                        Access API
                                                    </Link>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <Appname>
                                                        Messaging Limit:
                                                    </Appname>
                                                    <FinanceCash sx={{ mt: 1, }}>
                                                        NA
                                                    </FinanceCash>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <Appname>
                                                        Date & Time:
                                                    </Appname>
                                                    <FinanceCash sx={{ mt: 1, }}>
                                                        {moment(appList.created_at).format('DD-MM-YYYY HH:MM:SS A')}
                                                    </FinanceCash>
                                                </Grid>
                                                <Grid item md={3} sx={{ textAlign: 'end' }}>
                                                    <Appname className='codeBlockView'>
                                                        <Box component="span" sx={{ fontSize: '14px' }}>Status:</Box>
                                                        <Typography >
                                                            <Box component="span" sx={{ color: ((appList.status === 1) ? ('green') : ('red')), fontSize: '14px' }}>
                                                                {((appList.status === 1) ? ('Active') : ('In Active'))}
                                                            </Box> -                                                    <Box component="span" sx={{ color: ((appList.is_wb_account_connect === 1) ? ('green') : ('red')), fontSize: '14px' }}>
                                                                {((appList.is_wb_account_connect === 1) ? ('Connected') : ('Not Connected'))}
                                                            </Box>
                                                        </Typography>
                                                        {((appList.is_wb_account_connect === 1) && (<>
                                                            <CopyBlock language="javascript" text={appList.app_code} theme={a11yLight} showLineNumbers={false} />
                                                        </>))}
                                                    </Appname>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            ))}
                        </Companydetails>
                        {/* <Companydetails value={value} index={1}>
                            Item Two
                        </Companydetails> */}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}