import {
  Grid,
  TextField
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { SuggestedActionMenu, SuggestedActionMenuDiv } from "./toggleButton";
import { VariableDropdown } from "./VariableDropdown";

export const TextSection = ({ formik, handleDeleteSuggestedAction,handleVariableAddition }) => {
  return (
    <>
      <Grid item md={6}>
        <TextField
          id="textMessage"
          label="Enter Text Message *"
          multiline          
          rows={3}
          fullWidth
          placeholder="Maximum 2500 characters..."
          InputLabelProps={{
            sx: {
              color: "#92a2b1",
              fontSize: 15,
              fontWeight: 400,
            },
          }}
          inputProps={{
            sx: {
              color: "#000000de",
              fontSize: 13,
              fontWeight: 500,
            },
            name:"textMessage",
            value:formik.values.textMessage            
          }}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item md={12}></Grid>
      {formik.values.templateVariables.length > 0 && (
        <Grid item md={6}>
          <VariableDropdown templateVariables={formik.values.templateVariables} handleVariableAddition={handleVariableAddition}/>
        </Grid>
      )}
      <Grid item md={12}></Grid>
      <Grid item md={6}>
        <SuggestedActionMenu formik={formik} />
      </Grid>
      <Grid item md={12}></Grid>
      <Grid item md={6}>
        {formik.values.selectedSuggestedActions.map((action, index) => (
          <SuggestedActionMenuDiv
            actionId={action}
            index={index}
            handleDeleteSuggestedAction={handleDeleteSuggestedAction}
          />
        ))}
      </Grid>
    </>
  );
};
