import { Avatar, Box, Container, Grid, IconButton, List, ListItem, ListItemIcon, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../Images/login-logo.png';
import Profileimg from '../../Images/profile-picture.jpg'
import Profileicon from '@mui/icons-material/AccountCircleOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { manageUserSession } from "../../Reduxstore/authSlice";
import { useDispatch, useSelector } from 'react-redux';
// toast
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';


const listStyle = {
    display: 'inline-flex',
    flexDirection: 'row',
};

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    let userDetails = authState?.postItems?.data?.userDetails;
    const submitLogout = async () => await dispatch(manageUserSession('logout'))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const submenuDropdown = {
        px: 2, py: 0, fontSize: 15, color: '#212529'
    };
    return (
        <>
            <Grid sx={{ background: '#fff', borderBottom: 1, borderColor: '#eaeff5', padding: 1, }}>
                <Container>
                    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item md={2}>
                            <Link to="/admin/dashboard">
                                <img src={Logo} alt="Logo" />
                            </Link>
                        </Grid>
                        <Grid item md={3}>
                            <List style={listStyle}>
                                <ListItem>
                                    <Link style={{ color: '#32393a', fontSize: 15, fontWeight: 500, textDecoration: 'none' }} to='/admin/dashboard'>Dashboard</Link>
                                </ListItem>
                                <ListItem>
                                    <Link style={{ color: '#32393a', fontSize: 15, fontWeight: 500, textDecoration: 'none' }} to='/admin/companies'>Companies</Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={7} sx={{ display: 'flex', justifyContent: "end", alignItems: 'center' }}>
                            <Stack
                                direction='row'
                                alignItems='center'
                                spacing={{ xs: 0.5, sm: 1.5 }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <Tooltip title="Myaccount">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{
                                                ml: 0,
                                                "&.MuiButtonBase-root:hover": {
                                                    bgcolor: "transparent"
                                                }
                                            }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}

                                        >
                                            <Avatar alt="Profile" src={Profileimg} sx={{ borderRadius: 3, mr: 1 }} />

                                            <Grid>
                                                <Typography level="title-sm" sx={{ color: '#92a2b1', fontSize: 12, }}>Customer:</Typography>
                                                <Typography level="body-sm" sx={{ fontSize: 14, color: '#32393a', fontWeight: 500 }}>{userDetails?.firstname + ' ' + userDetails?.lastname}
                                                </Typography>
                                            </Grid>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleClose} style={submenuDropdown}>
                                        <ListItemIcon sx={{ p: 0, }} style={{ minWidth: 28 }}>
                                            <Profileicon fontSize="small" />
                                        </ListItemIcon>
                                        My Profile
                                    </MenuItem>
                                    <MenuItem style={submenuDropdown} onClick={() => submitLogout()}>
                                        <ListItemIcon sx={{ p: 0, }} style={{ minWidth: 28 }}>
                                            <LoginOutlinedIcon fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Stack>

                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Header