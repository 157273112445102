import { Button, Container, FormControl, Grid, InputAdornment, OutlinedInput, TextField, } from "@mui/material";

const Admindashboard = () => {
  return (
    <>
      <Container >
        <Grid container>
          {'dashboard'}
        </Grid>
      </Container >
    </>
  )
}

export default Admindashboard