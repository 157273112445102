import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { authpostAxios } from "../Utilities/commonAxios";
import moment from "moment";
import MessageStatusReport from "./Report/messageStatusReport";
import ViewWhatsappTemplates from "./WhatsappTemplate/view";
import ViewRCSTemplates from "./RcsTemplate/view";
import UserWebhook from "./Webhook/userWebhook";
import { useDispatch } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AppView = () => {
  const dispatch = useDispatch();
  const { AppId } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value, setValue] = React.useState(0);
  // app details
  const [appDetails, setAppDetails] = useState([]);
  const getAppDetailsFromAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-createappdetails/${AppId}`,
        data: "",
      },
      dispatch
    ).then((getAppDetailsAPIResponse) => {
      if (getAppDetailsAPIResponse.data.data) {
        setAppDetails(getAppDetailsAPIResponse.data?.data);
        localStorage.setItem(
          "AppConnectStatus",
          btoa(getAppDetailsAPIResponse.data.data.is_wb_account_connect)
        );
      }
    });
  };
  // over all report
  const [getDailyReportDetails, setGetDailyReportDetails] = useState([]);
  const getDailyReportDetailsAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-dayby-report/${AppId}`,
        data: "",
      },
      dispatch
    ).then((reportResponse) =>
      setGetDailyReportDetails(reportResponse.data?.data)
    );
  };
  // day by day report
  const [getOverallReportDetails, setGetOverallReportDetails] = useState([]);
  const getOverallReportDetailsAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-overall-report/${AppId}`,
        data: "",
      },
      dispatch
    ).then((reportResponse) =>
      setGetOverallReportDetails(reportResponse.data?.data)
    );
  };
  useEffect(() => {
    getAppDetailsFromAPI();
    getOverallReportDetailsAPI();
    getDailyReportDetailsAPI();
  }, []);
  return (
    <>
      <Container>
        <Card
          md={12}
          sx={{
            mt: 3,
            mb: 3,
            boxShadow: "1px 1px 8px rgb(0 0 0 / 17%)",
            border: "unset",
          }}
          variant="outlined"
        >
          <CardContent>
            <Grid container>
              <Grid item md={7}>
                <Tabs value={value} onChange={handleChange} aria-label="">
                  <Tab
                    label="Templates"
                    {...a11yProps(0)}
                    sx={{
                      textTransform: "capitalize",
                      color: "#32393a",
                      fontSize: 14,
                    }}
                  />
                  <Tab
                    label="Webhook"
                    {...a11yProps(1)}
                    sx={{
                      textTransform: "capitalize",
                      color: "#32393a",
                      fontSize: 14,
                    }}
                  />
                  <Tab
                    label="Report"
                    {...a11yProps(2)}
                    sx={{
                      textTransform: "capitalize",
                      color: "#32393a",
                      fontSize: 14,
                    }}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <Grid item md={12} sx={{ borderTop: 1, borderColor: "divider" }}>
              <CustomTabPanel value={value} index={0}>
                {appDetails.type_id === 1 && <ViewWhatsappTemplates AppId={AppId} />}
                {appDetails.type_id === 2 && <ViewRCSTemplates AppId={AppId} />}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <UserWebhook AppId={AppId} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <MessageStatusReport
                  appDetails={appDetails}
                  getOverallReportDetails={getOverallReportDetails}
                  getDailyReportDetails={getDailyReportDetails}
                />
              </CustomTabPanel>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AppView;
