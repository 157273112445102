import { Button, Checkbox, Container, Divider, FormControlLabel, FormGroup, Grid, Typography, } from "@mui/material"
import 'react-quill/dist/quill.snow.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InteraktConnectButton from "@haptik/interakt-connect-button";
import "@haptik/interakt-connect-button/dist/style.css";
// import styled from "@emotion/styled";
import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import { authpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from "react-redux";

const Connectwhatsapp = () => {
    const dispatch = useDispatch()
    const { AppId } = useParams();
    const handleCallback = async (callbackObject) => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/connect-wb/${AppId}`,
                'data': {
                    'api_key': callbackObject.publicApiSecretKey,
                    'channel_id': callbackObject.phoneNumberId,
                    'wb_api_number': callbackObject.waApiNumber,
                    'waba_id': callbackObject.wabaId,
                }
            }, dispatch)
    };
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item md={12} sx={{ mb: 2 }}>
                        <Typography sx={{ fontSize: 15, fontWeight: 600, }}>
                            Connect your whatsapp Business Account
                        </Typography>
                    </Grid>
                    <Grid item md={12} sx={{ mb: 1, mt: 2 }}>
                        <InteraktConnectButton partnerId={"72e505f4-850a-4b86-ba77-63137f15b955"} callback={handleCallback} />
                    </Grid>
                </Grid>
                <Divider />
            </Container>
        </>
    )
}

export default Connectwhatsapp