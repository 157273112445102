import Register from "../Authentication/register"
import Loginsection from "../Authentication/login";
import Forgotpassword from "../Authentication/forgotpassword";
import Dashboard from "../Dashboard/dashboard"
import CreateApp from "../App/createApp";
import Companydetails from "../Companies/companydetails";
import Topuprequest from "../Mywallet/topuprequest";
import AppView from "../App/appView";
import CreateWhatsappTemplate from "../App/WhatsappTemplate/create";
import CreateCarouselTemplate from "../App/WhatsappTemplate/create-carousel";
import CreateRcsTemplate from "../App/RcsTemplate/create";
import Connectwhatsapp from "../App/connectWhatsapp";
import Index from "../Mywallet/index";
import Addwalletbalance from "../Mywallet/addwalletbalance";
import MakePayment from "../Payments/makePayment";
import PaymentResponse from "../Payments/paymentresponse";
import ErrorPage from "../Error/404";
// home
import HomeIndex from "../Home/index";
import Privacy from "../Home/privacy";
import Terms from "../Home/terms";
import Faq from "../Home/faq";
import ApiDocs from "../ApiDocs/index";


const homeRoutes = [
    { path: '/', component: <HomeIndex /> },
    { path: '/privacy-policy', component: <Privacy /> },
    { path: '/terms-and-conditions', component: <Terms /> },
    { path: '/faq', component: <Faq /> }
];

const apiDocsRoutes = [
    { path: '/api-docs', component: <ApiDocs /> }
]
const publicRoutes = [
    { path: '/login', component: <Loginsection /> },
    { path: '/register', component: <Register /> },
    { path: '/forgotpassword', component: <Forgotpassword /> }
];

const authProtectedRoutes = [
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/app-connect/:AppId', component: <Connectwhatsapp /> },
    // { path: '/connect-whatsapp', component: <Connectwhatsapp /> },
    { path: '/create-app', component: <CreateApp /> },
    { path: '/companydetails', component: <Companydetails /> },
    { path: '/topuprequest', component: <Topuprequest /> },
    { path: '/my-wallet', component: <Index /> }
];

const authProtectedConnectRoutes = [
    { path: '/app-view/:AppId', component: <AppView /> },
    { path: '/create-whatsapp-template/:AppId', component: <CreateWhatsappTemplate /> },
    { path: '/create-carousel-template/:AppId', component: <CreateCarouselTemplate /> },
    { path: '/create-rcs-template/:AppId', component: <CreateRcsTemplate /> },
];

const authProtectedPaymentRoutes = [
    { path: '/add-balance', component: <Addwalletbalance /> },
    { path: '/payment-response', component: <PaymentResponse /> },
    { path: '/add-to-wallet', component: <MakePayment /> }
];

const errorRoutes = [{ path: '*', component: <ErrorPage /> }];

export { homeRoutes, apiDocsRoutes, publicRoutes, authProtectedRoutes, authProtectedConnectRoutes, authProtectedPaymentRoutes, errorRoutes }