import { Button, Grid, TextField, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loginbgsection from "../../Authentication/loginbgsection";
import { adminAuthLogin } from "../../Reduxstore/authSlice";
import { useDispatch } from 'react-redux';
// toast
import { toast } from "react-toastify";

const AdminLogin = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // form submission
    const loginForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: 'test@gmail.com',
            password: 'prem1234',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Username"),
            password: Yup.string().required("Please Enter Password"),
        }),
        onSubmit: async (values) => {
            let loginResponseData = await dispatch(adminAuthLogin({ emailid: values.email, userpassw: values.password }))
            if (loginResponseData.payload.flag === 1) {
                localStorage.setItem('userToken', loginResponseData.payload.data.userToken)
                navigate('/admin/dashboard')
                toast.success(loginResponseData.payload.message);
            } else {
                localStorage.removeItem('userToken')
                toast.error(loginResponseData.payload.message);
            }
        }
    });
    return (
        <>
            <Grid className="second-section">
                <Grid item md={12}>
                    <Grid className="outer-box-style">
                        <Typography sx={{ fontSize: 24, color: '#32393a', fontWeight: 500, marginBottom: 1 }}>
                            Admin Login
                        </Typography>
                        <form onSubmit={loginForm.handleSubmit}>
                            <Grid className="mb-2 mt-3">
                                <TextField size="small" label="Email Id" name="email" onChange={loginForm.handleChange} value={loginForm.values.email} variant="outlined" sx={{ width: '45ch' }} />
                                {(loginForm.errors.email) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{loginForm.errors.email}</Typography>) : (null)}
                            </Grid>
                            <Grid className="mb-2 mt-3">
                                <TextField type="password" size="small" label="Password" name="password" onChange={loginForm.handleChange} value={loginForm.values.password} variant="outlined" sx={{ width: '45ch' }} />
                                {(loginForm.errors.password) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{loginForm.errors.password}</Typography>) : (null)}
                            </Grid>
                            <Grid className="mt-2 mb-3">
                                <Button variant="contained" type='submit' fullWidth sx={{ backgroundColor: '#32393a', borderRadius: 1.3, padding: 1.5 }}>Sign in</Button>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default AdminLogin