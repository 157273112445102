import { Grid } from "@mui/material"
import Index from "../App/index"


const Dashboard = (props) => {
  return (
    <>      
      <Grid>
        <Index />
      </Grid>
    </>
  )
}

export default Dashboard