import { Container, Grid, Stepper, Box, Step, StepButton, Typography, Button, Card, TextField, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useFormik } from 'formik';
import { authpostAxios } from "../Utilities/commonAxios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

const whatsuppsection = {
  paddingTop: '80px',
};

const steps = ['WhatsApp API', 'App Name'];

const CreateApp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [hiddenValue, setHiddenValue] = useState('');
  const handleChange = (e) => {
    setHiddenValue(e.target.value);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const submitcreateappForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      appType: '',
      app_name: ''
    },
    validationSchema: Yup.object({
      appType: Yup.string().required("Please Select App Type"),
      app_name: Yup.string().required("Please Enter App Name")
    }),

    onSubmit: async (values) => {
      if (!submitcreateappForm.dirty) {
        toast.error('No data has changed!')
      } else {
        let { data: usercompanyResponse } = await authpostAxios(
          {
            'url': `${process.env.REACT_APP_BACKENDURL}api/createapp`,
            'data': {
              "appType": values.appType,
              "app_name": values.app_name
            }
          }, dispatch)
        if (usercompanyResponse.flag === 1) {
          toast.success(usercompanyResponse.message)
          navigate('/dashboard');

        } else {
          toast.error(usercompanyResponse.message)
        }
      }
    }
  })
  // Get app types
  const [appTypes, setAppTypes] = useState([])
  useEffect(() => {
    const getAppTypes = async () => {
      await authpostAxios(
        {
          'url': `${process.env.REACT_APP_BACKENDURL}api/get-app-types`,
          'data': {}
        }, dispatch).then(response => setAppTypes(response?.data?.data))
    }
    getAppTypes()
  }, [])
  return (
    <>
      <Grid style={whatsuppsection}>
        <Container>
          <Grid container className="justify-content-md-center" >
            <Grid item md={4}>
              <Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton disabled={activeStep === 0}
                        sx={{
                          "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
                            color: '#313336',
                          }
                        }}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  <Grid item md={12}>
                    <Card variant="outlined" sx={{ mt: 5, p: 2, textAlign: 'center', boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset' }}>
                      {
                        (activeStep === 0) ? (
                          <>
                            <Grid>
                              <WhatsAppIcon sx={{ width: 45, height: 45, color: '#2cbf63' }} />
                            </Grid>
                            <Grid>
                              <Typography sx={{ color: '#27292f', fontSize: 16, fontWeight: 500, pt: 1.5 }}>WhatsApp API</Typography>
                              <Typography sx={{ color: '#313336', textAlign: 'center', fontSize: 15, pt: 2, pb: 4 }}>
                                Integrate WhatsApp API and automate your response using our bots to communicate with millions of customers. Now, also send template messages to your opted in users
                              </Typography>
                            </Grid>
                            <Grid sx={{ mb: 1 }}>
                              <Button variant='contained' style={{ background: '#16a6fb', fontSize: 14, fontWeight: 400, }} onClick={() => handleNext()}>Access API </Button>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid>
                              <Typography sx={{ color: '#27292f', fontSize: 16, fontWeight: 500, pt: 1.5 }}>Define a unique name for your WhatsApp Messaging App</Typography>
                            </Grid>
                            <form onSubmit={submitcreateappForm.handleSubmit} >
                              <Grid style={{ mb: 2, position: 'relative' }}>
                                <FormControl>
                                  <RadioGroup row defaultValue="none" name="radio_buttons_group" sx={{ "& .MuiFormControlLabel-label": { fontWeight: 500, fontSize: 14 } }}>
                                    {appTypes && appTypes?.map((appType, index) => (
                                      <FormControlLabel value={appType.id} id={`appType${index}`} name="appType" control={<Radio />} label={appType.name} checked={submitcreateappForm.values.appType === (appType.id).toString()} onChange={submitcreateappForm.handleChange} key={index} />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                                <Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitcreateappForm.errors.appType}</Typography>
                              </Grid>
                              <Grid sx={{ mb: 2, position: 'relative' }}>
                                <TextField onChange={submitcreateappForm.handleChange} onBlur={submitcreateappForm.handleBlur} value={submitcreateappForm.values.app_name} id="app_name" name="app_name" label="Create App" variant="outlined" size='small' sx={{ m: 1, width: '35ch' }} />
                                <Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitcreateappForm.errors.app_name}</Typography>
                              </Grid>
                              <Grid sx={{ mb: 1 }}>
                                <Button type="submit" variant='contained' style={{ background: '#16a6fb', fontSize: 14, fontWeight: 400, textTransform: 'capitalize', width: '38ch' }}>Create</Button>
                              </Grid>
                            </form>
                          </>
                        )}
                    </Card>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}

export default CreateApp