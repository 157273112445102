import Header from "../../Components/Admin/header"
import Bread from "../../Components/Admin/breadcrumb"
import { Grid } from "@mui/material"

export const AdminAuthLayout = props => {
    return (
        <>
            <Grid item md={12}>
                <Header />
            </Grid>
            <Grid item md={12}>
                <Bread />
            </Grid>
            <Grid item md={12}>
                {props.children}
            </Grid>
        </>
    );
}