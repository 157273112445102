import { Button, Card, Container, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import React from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { authpostAxios } from "../Utilities/commonAxios";
// toast
import { toast } from "react-toastify";
import { fileToBase64 } from '../Utilities/fileConversion';
import { useDispatch } from "react-redux";

const billingtop = {
    paddingTop: 10,
    marginBottom: 30,
};

const billingstyle = {
    fontSize: 20,
    fontWeight: 600,
    color: '#32393a',
}
const cancebtncustom = {
    border: "1px solid #cfd7df",
    color: '#313336',
    width: '20ch',
    marginRight: '10px',
    fontSize: '14px',
    textTransform: 'capitalize',
    padding: '10px'
}
const savebtn = {
    backgroundColor: '#16a6fb',
    width: '20ch',
    fontSize: '14px',
    textTransform: 'capitalize',
    padding: '10px'
}
const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    padding: 1,
    boxSizing: 'border-box',
    ObjectFit: 'contain',
    placeItems: 'center',
    color: '#000',
    paddingRight: '10px',
    fontWeight: 500,
    marginTop: 1.5,
};
const img = {
    display: 'block',
    width: '80px',
    height: '80px',
    objectFit: 'contain',
};
const Companydetails = () => {
    const dispatch = useDispatch()
    // initial company data
    const [userCompanyDetails, setUserCompanyDetails] = useState([]);
    const getUserCompanyDetailsFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/usercompanydetails`,
                'data': ''
            },dispatch).then(userCompanyDetailsResponse => setUserCompanyDetails(userCompanyDetailsResponse.data?.data))
    }
    const submitCompanyForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyname: ((userCompanyDetails) ? (userCompanyDetails.companyname) : ("")),
            phone: ((userCompanyDetails) ? (userCompanyDetails.mobileno) : ("")),
            emailid: ((userCompanyDetails) ? (userCompanyDetails.emailid) : ("")),
            timezone: ((userCompanyDetails) ? (`${userCompanyDetails.timezoneid}`) : ("")),
            address1: ((userCompanyDetails) ? (userCompanyDetails.address1) : ("")),
            address2: ((userCompanyDetails) ? (userCompanyDetails.address2) : ("")),
            country: ((userCompanyDetails) ? (`${userCompanyDetails.countryid}`) : ("")),
            state: ((userCompanyDetails) ? (`${userCompanyDetails.stateid}`) : ("")),
            city: ((userCompanyDetails) ? (`${userCompanyDetails.cityid}`) : ("")),
            postalcode: ((userCompanyDetails) ? (userCompanyDetails.pincode) : ("")),
            businesscategory: ((userCompanyDetails) ? (`${userCompanyDetails.businesstype}`) : ("")),
            companysize: ((userCompanyDetails) ? (`${userCompanyDetails.companysize}`) : ("")),
            gstno: ((userCompanyDetails) ? (userCompanyDetails.gstnumber) : ("")),
            panno: ((userCompanyDetails) ? (userCompanyDetails.pannumber) : ("")),
            companylogo: "",
            companylogoExistPath: ((userCompanyDetails) ? (userCompanyDetails.logo_idproofpath) : ("")),
            gstProof: "",
            gstProofExistPath: ((userCompanyDetails) ? (userCompanyDetails.gst_idproofpath) : ("")),
            panProof: "",
            panProofExistPath: ((userCompanyDetails) ? (userCompanyDetails.pan_idproofpath) : ("")),
        },
        validationSchema: Yup.object({
            companyname: Yup.string().required("Please Enter Company name"),
            phone: Yup.string().required("Please Enter Mobile Number"),
            emailid: Yup.string().required("Please Enter Email"),
            timezone: Yup.string().required("Please Select Timezone"),
            address1: Yup.string().required("Please Enter Address1"),
            address2: Yup.string().required("Please Enter Address2"),
            country: Yup.string().required("Please Select Country"),
            state: Yup.string().required("Please Select State"),
            city: Yup.string().required("Please Select City"),
            postalcode: Yup.string().required("Please Enter Postal Code"),
            businesscategory: Yup.string().required("Please Select Business Category"),
            companysize: Yup.string().required("Please Select Company Size"),
            gstno: Yup.string().required("Please Enter GST No"),
            panno: Yup.string().required("Please Enter PAN No")
        }),
        onSubmit: async (values) => {
            if (!submitCompanyForm.dirty) {
                toast.error('No data has changed!')
            } else {
                let { data: usercompanyResponse } = await authpostAxios(
                    {
                        'url': `${process.env.REACT_APP_BACKENDURL}api/usercompany`,
                        'data': {
                            "companyname": values.companyname,
                            "mobileno": values.phone,
                            "emailid": values.emailid,
                            "timezoneid": values.timezone,
                            "address1": values.address1,
                            "address2": values.address2,
                            "countryid": values.country,
                            "stateid": values.state,
                            "cityid": values.city,
                            "pincode": values.postalcode,
                            "businesstype": values.businesscategory,
                            "companysize": values.companysize,
                            "pannumber": values.panno,
                            "gstnumber": values.gstno,
                            "companylogo": (values.companylogo === "") ? ("") : (await fileToBase64(values.companylogo)),
                            "companylogoExistPath": values.companylogoExistPath,
                            "gstProof": (values.gstProof === "") ? ("") : (await fileToBase64(values.gstProof)),
                            "gstProofExistPath": values.gstProofExistPath,
                            "panProof": (values.panProof === "") ? ("") : (await fileToBase64(values.panProof)),
                            "panProofExistPath": values.panProofExistPath
                        }
                    },dispatch)
                if (usercompanyResponse.flag === 1) {
                    submitCompanyForm.setFieldValue('companylogo', '')
                    submitCompanyForm.setFieldValue('gstProof', '')
                    submitCompanyForm.setFieldValue('panProof', '')
                    getUserCompanyDetailsFromAPI()
                    toast.success(usercompanyResponse.message)
                } else {
                    toast.error(usercompanyResponse.message)
                }
            }
        }
    })
    // timezone
	
    const [timezoneLists, setTimezoneLists] = useState([]);
    const getTimeZoneFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-timezone`,
                'data': ''
            },dispatch).then(timeZoneResponse => setTimezoneLists(timeZoneResponse.data?.data))
    }
    // business Category
    const [businessCategoryLists, setBusinessCategoryLists] = useState([]);
    const getbusinessCategoryFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-businesscategory`,
                'data': ''
            },dispatch).then(businessCategoryResponse => setBusinessCategoryLists(businessCategoryResponse.data?.data))
    }
    // company Size
    
    const [companySizeLists, setCompanySizeLists] = useState([]);
    const getcompanySizeFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-companysize`,
                'data': ''
            },dispatch).then(companySizeResponse => setCompanySizeLists(companySizeResponse.data?.data))
    }

    // country
    const [countryLists, setCountryLists] = useState([]);
    const getCountriesFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-country`,
                'data': ''
            },dispatch).then(countryResponse => setCountryLists(countryResponse.data?.data))
    }
    // state
    const [stateLists, setStateLists] = useState([]);
    const getStateFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-state`,
                'data': {
                    'countryid': submitCompanyForm.values.country
                }
            },dispatch).then(stateResponse => setStateLists(stateResponse.data?.data))
    }
    // city
    const [cityLists, setCityLists] = useState([]);
    const getCityFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-city`,
                'data': {
                    'stateid': submitCompanyForm.values.state
                }
            },dispatch).then(cityResponse => setCityLists(cityResponse.data?.data))
    }
    useEffect(() => {
        getUserCompanyDetailsFromAPI()
        getCountriesFromAPI()
        getTimeZoneFromAPI()
        getbusinessCategoryFromAPI()
        getcompanySizeFromAPI()
    }, [])
    // for state   
    useEffect(() => {
        if (submitCompanyForm.values.country !== "undefined" && submitCompanyForm.values.country !== "") {
            setStateLists([]);
            setCityLists([]);
            getStateFromAPI()
        }
    }, [submitCompanyForm?.values?.country])
    // for city
    useEffect(() => {
        if (submitCompanyForm.values.state !== "undefined" && submitCompanyForm.values.state !== "") {
            setCityLists([]);
            getCityFromAPI()
        }
    }, [submitCompanyForm?.values?.state])
    // image preview
    const [selectedCompanyLogo, setSelectedCompanyLogo] = useState();
    const [selectedGSTImage, setSelectedGSTImage] = useState();
    const [selectedPANImage, setSelectedPANImage] = useState();
    return (
        <>
            <Grid style={billingtop}>
                <Container>
                    <Grid item md={12}>
                        <Typography style={billingstyle}>
                            Company Details
                        </Typography>
                    </Grid>
                    <Grid item md={12} sx={{ mt: 4 }}>
                        <Card variant="outlined" sx={{ p: 3,boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border:'unset' }}>
                            <form onSubmit={submitCompanyForm.handleSubmit}>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <Grid sx={{ mr: 2, mb: 3 }}>
                                            <TextField size="small" label='Company Name' name="companyname"
                                                value={submitCompanyForm.values.companyname} defaultValue="Company Name" onChange={submitCompanyForm.handleChange} fullWidth InputLabelProps={{
                                                    sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                                }} inputProps={{
                                                    sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                                }} />
                                            {(submitCompanyForm.errors.companyname) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.companyname}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mr: 2, mb: 3 }}>
                                            <TextField size="small" label='Company Email ID' name="emailid" value={submitCompanyForm.values.emailid} defaultValue="Company Email ID" onChange={submitCompanyForm.handleChange} fullWidth InputLabelProps={{
                                                sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                            }} inputProps={{
                                                sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                            }} />
                                            {(submitCompanyForm.errors.emailid) ? (<Typography variant="body2" sx={{ color: '#000', fontSize: 14 }}>{submitCompanyForm.errors.emailid}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mr: 2, mb: 3 }}>
                                            <TextField size="small" label='Address line 1' defaultValue='Address line 1' name="address1" value={submitCompanyForm.values.address1} onChange={submitCompanyForm.handleChange} fullWidth InputLabelProps={{
                                                sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                            }} inputProps={{
                                                sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                            }} />
                                            {(submitCompanyForm.errors.address1) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.address1}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mr: 2, mb: 3 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="country">Country</InputLabel>
                                                <Select size="small" name="country" labelId="country" label="Country" value={submitCompanyForm.values.country} onChange={submitCompanyForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} input={<OutlinedInput label="Select Country" />}
                                                >
                                                    {countryLists ? countryLists.map((countryList, key) => (
                                                        <MenuItem value={countryList.countryid} key={key}>{countryList.countryname}</MenuItem>
                                                    )) : null}
                                                </Select>
                                            </FormControl>
                                            {(submitCompanyForm.errors.country) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.country}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mr: 2, mb: 3 }}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="city">City</InputLabel>
                                                <Select size="small" name="city" labelId="city" label="City" value={submitCompanyForm.values.city} onChange={submitCompanyForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} input={<OutlinedInput label="Select City" />}>
                                                    {cityLists ? cityLists.map((cityList, key) => (
                                                        <MenuItem value={cityList.cityid} key={key}>{cityList.cityname}</MenuItem>
                                                    )) : null}
                                                </Select>
                                            </FormControl>
                                            {(submitCompanyForm.errors.city) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.city}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mr: 2, mb: 3 }}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="businesscategory">Business Category</InputLabel>
                                                <Select size="small" name="businesscategory" labelId="businesscategory" label="Business Category" value={submitCompanyForm.values.businesscategory} onChange={submitCompanyForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} input={<OutlinedInput label="Select Business Category" />}>
                                                    {businessCategoryLists ? businessCategoryLists.map((businessCategoryList, key) => (
                                                        <MenuItem value={businessCategoryList.business_category_id} key={key}>{businessCategoryList.business_category_name}</MenuItem>
                                                    )) : null}
                                                </Select>
                                            </FormControl>
                                            {(submitCompanyForm.errors.businesscategory) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.businesscategory}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mr: 2, mb: 3 }}>
                                            <TextField size="small" label='GST No' defaultValue='GST No' name="gstno" value={submitCompanyForm.values.gstno} onChange={submitCompanyForm.handleChange} fullWidth InputLabelProps={{
                                                sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                            }} inputProps={{
                                                sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                            }} />
                                            {(submitCompanyForm.errors.gstno) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.gstno}</Typography>) : (null)}
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Grid sx={{ mb: 3 }}>
                                            <TextField size="small" label='Phone number' defaultValue='Phone number' name="phone" value={submitCompanyForm.values.phone} onChange={submitCompanyForm.handleChange} fullWidth InputLabelProps={{
                                                sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                            }} inputProps={{
                                                sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                            }} />
                                            {(submitCompanyForm.errors.phone) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.phone}</Typography>) : (null)}
                                        </Grid>
										
                                        <Grid sx={{ mb: 3 }}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="timezone">Default Timezone</InputLabel>
                                                <Select size="small" name="timezone" labelId="timezone" label="Default Timezone" value={submitCompanyForm.values.timezone} onChange={submitCompanyForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} input={<OutlinedInput label="Select Default Timezone" />}>
                                                    {timezoneLists ? timezoneLists.map((timezoneList, key) => (
                                                        <MenuItem value={timezoneList.timezone_id} key={key}>{timezoneList.timezone_name}</MenuItem>
                                                    )) : null}
                                                </Select>
                                            </FormControl>
                                            {(submitCompanyForm.errors.timezone) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.timezone}</Typography>) : (null)}
                                        </Grid>
										
                                        <Grid sx={{ mb: 3 }}>
                                            <TextField size="small" label='Address line 2' defaultValue='Address line 2' name="address2" value={submitCompanyForm.values.address2} onChange={submitCompanyForm.handleChange} fullWidth InputLabelProps={{
                                                sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                            }} inputProps={{
                                                sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                            }} />
                                            {(submitCompanyForm.errors.address2) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.address2}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mb: 3 }}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="state">State</InputLabel>
                                                <Select size="small" name="state" labelId="state" label="State" value={submitCompanyForm.values.state} onChange={submitCompanyForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} input={<OutlinedInput label="Select State" />}>
                                                    {stateLists ? (stateLists.map((stateList, key) => (
                                                        <MenuItem value={stateList.stateid} key={key}>{stateList.statename}</MenuItem>
                                                    ))) : null}
                                                </Select>
                                            </FormControl>
                                            {(submitCompanyForm.errors.state) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.state}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mb: 3 }}>
                                            <TextField size="small" label='Zipcode / Postal code' defaultValue='Zipcode / Postal code' name="postalcode" value={submitCompanyForm.values.postalcode} onChange={submitCompanyForm.handleChange} fullWidth InputLabelProps={{
                                                sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                            }} inputProps={{
                                                sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                            }} />
                                            {(submitCompanyForm.errors.postalcode) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.postalcode}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mb: 3 }}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="companysize">Company size</InputLabel>
                                                <Select size="small" name="companysize" labelId="companysize" label="Company size" value={submitCompanyForm.values.companysize} onChange={submitCompanyForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} input={<OutlinedInput label="Select Company size" />}>
                                                    {companySizeLists ? (companySizeLists.map((companySizeList, key) => (
                                                        <MenuItem value={companySizeList.company_size_id} key={key}>{companySizeList.company_size}</MenuItem>
                                                    ))) : null}
                                                </Select>
                                            </FormControl>
                                            {(submitCompanyForm.errors.companysize) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.companysize}</Typography>) : (null)}
                                        </Grid>
                                        <Grid sx={{ mb: 3 }}>
                                            <TextField label='Pan No' defaultValue='Pan No' name="panno" value={submitCompanyForm.values.panno} onChange={submitCompanyForm.handleChange} size="small" fullWidth InputLabelProps={{
                                                sx: { color: '#92a2b1', fontSize: 13, fontWeight: 500 }
                                            }} inputProps={{
                                                sx: { color: '#000000de', fontSize: 13, fontWeight: 500 }
                                            }} />
                                            {(submitCompanyForm.errors.panno) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitCompanyForm.errors.panno}</Typography>) : (null)}
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2} sx={{ mb: 3 }}>
                                        <Grid item md={4}>
                                            <Grid sx={{}}>
                                                <input
                                                    type="file"
                                                    name="gstProof"
                                                    id="gstProof"
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={
                                                        (e) => {
                                                            submitCompanyForm.setFieldValue('gstProof', e.currentTarget.files[0])
                                                            setSelectedGSTImage(e.currentTarget.files[0])
                                                        }
                                                    }
                                                />
                                                <Button
                                                    component="label"
                                                    htmlFor="gstProof"
                                                    fullWidth variant="outlined" style={{ border: '1px solid #cfd7df', color: '#92a2b1', textTransform: 'capitalize', fontSize: '15px' }} startIcon={<CloudUploadIcon />}
                                                    onChange={
                                                        (e) => {
                                                            submitCompanyForm.setFieldValue('gstProof', e.currentTarget.files[0])
                                                            setSelectedGSTImage(e.currentTarget.files[0])
                                                        }
                                                    }
                                                >
                                                    GST Proof Choose file
                                                </Button>
                                                <Grid sx={thumb}>
                                                    {(selectedGSTImage) ? (
                                                        <img src={URL.createObjectURL(selectedGSTImage)} style={img} />
                                                    ) : (
                                                        submitCompanyForm.values.gstProofExistPath &&
                                                        <img src={submitCompanyForm.values.gstProofExistPath} style={img} />
                                                    )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Grid sx={{}}>
                                                <input
                                                    type="file"
                                                    name="panProof"
                                                    id="panProof"
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={
                                                        (e) => {
                                                            submitCompanyForm.setFieldValue('panProof', e.currentTarget.files[0])
                                                            setSelectedPANImage(e.currentTarget.files[0])
                                                        }
                                                    }
                                                />
                                                <Button
                                                    component="label"
                                                    htmlFor="panProof"
                                                    fullWidth variant="outlined" style={{ border: '1px solid #cfd7df', color: '#92a2b1', textTransform: 'capitalize', fontSize: '15px' }} startIcon={<CloudUploadIcon />}
                                                    onChange={
                                                        (e) => {
                                                            submitCompanyForm.setFieldValue('panProof', e.currentTarget.files[0])
                                                            setSelectedPANImage(e.currentTarget.files[0])
                                                        }
                                                    }
                                                >
                                                    PAN Proof Choose file
                                                </Button>
                                                <Grid sx={thumb}>
                                                    {(selectedPANImage) ? (
                                                        <img src={URL.createObjectURL(selectedPANImage)} style={img} />
                                                    ) : (
                                                        submitCompanyForm.values.panProofExistPath &&
                                                        <img src={submitCompanyForm.values.panProofExistPath} style={img} />
                                                    )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Grid sx={{}}>
                                                <input
                                                    type="file"
                                                    name="companylogo"
                                                    id="companylogo"
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={
                                                        (e) => {
                                                            submitCompanyForm.setFieldValue('companylogo', e.currentTarget.files[0])
                                                            setSelectedCompanyLogo(e.currentTarget.files[0])
                                                        }
                                                    }
                                                />
                                                <Button
                                                    component="label"
                                                    htmlFor="companylogo"
                                                    fullWidth variant="outlined" style={{ border: '1px solid #cfd7df', color: '#92a2b1', textTransform: 'capitalize', fontSize: '15px' }} startIcon={<CloudUploadIcon />}
                                                    onChange={
                                                        (e) => {
                                                            submitCompanyForm.setFieldValue('companylogo', e.currentTarget.files[0])
                                                            setSelectedCompanyLogo(e.currentTarget.files[0])
                                                        }
                                                    }
                                                >
                                                    Company Logo Choose file
                                                </Button>
                                                <Grid sx={thumb}>
                                                    {(selectedCompanyLogo) ? (
                                                        <img src={URL.createObjectURL(selectedCompanyLogo)} style={img} />
                                                    ) : (
                                                        submitCompanyForm.values.companylogoExistPath &&
                                                        <img src={submitCompanyForm.values.companylogoExistPath} style={img} />
                                                    )
                                                    }
                                                </Grid>                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12} sx={{ textAlign: 'end' }}>
                                        <Button variant="outlined" style={cancebtncustom}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="contained" style={savebtn}>Save</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Companydetails