import { Container, Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authpostAxios } from "../../Utilities/commonAxios";
// toast
import { TemplateHeader } from "./TemplateHeader";
import { TemplateFooter } from "./TemplateFooter";

const CreateRcsTemplate = () => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      templateName: "",
      templateType: "3",
      singleVariable: "",
      templateVariables: [],
      selectedSuggestedActions: [],
      textMessage: "",
      richCardTitle: "",
      richCardDescription: "",
      carouselLists: [0, 1],
      carouselDataTitle: [],
      carouselDataDescription: []
    },
    validationSchema: Yup.object({
      templateName: Yup.string().required("Please Enter template name"),
      templateType: Yup.string().required("Please Select template type"),
    }),
    onSubmit: async (values) => {
      console.log('carouselDataTitle',values.carouselDataTitle)
      console.log('carouselDataDescription',values.carouselDataDescription)
      console.log("values", values);
    },
  });
  return (
    <>
      <Container>
        <Grid container>
          <Grid item md={12}>
            <Typography
              sx={{ color: "#32393a", fontSize: 18, fontWeight: 500, mb: 2 }}
            >
              Create RCS Template
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid container item spacing={2}>
            <Grid item md={9}></Grid>
            <TemplateHeader formik={formik} />
            <TemplateFooter formik={formik} />
          </Grid>
        </form>
      </Container>
    </>
  );
};
export default CreateRcsTemplate;
