import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { authpostAxios } from "../Utilities/commonAxios";
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import '../Style/style.css';
import { useDispatch } from "react-redux";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Addtaps = () => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // topup details
    const [topupDetails, setTopupDetails] = useState([]);
    const [tableData, setTableData] = useState([]);
    const getTopupDetailsFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-topupdetails`,
                'data': ''
            },dispatch).then(getTopupDetailsResponse => {
                setTopupDetails(getTopupDetailsResponse.data?.data)
            })
    }
    useEffect(() => {
        getTopupDetailsFromAPI()
    }, [])
    useEffect(() => {
        let newTableData = [];
        topupDetails && topupDetails.map((setTopupDetail, index) => {
            newTableData.push([setTopupDetail.transaction_id, setTopupDetail.credit_amount, ((setTopupDetail.transaction_date) ? (moment(setTopupDetail.transaction_date).format('DD-MM-YYYY HH:MM:SS A')) : (''))])
        })
        setTableData(newTableData)
    }, [topupDetails])
    // table-options    
    const [searchBtn, setSearchBtn] = useState(true);
    const options = {
        search: searchBtn,
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none'
    }
    // table-columns
    const columns = [
        "Transaction ID",
        "Transaction Amount",
        "Transaction Date/Time"
    ];
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab sx={{ fontSize: 15, textTransform: 'capitalize' }} label="Topup Details" {...a11yProps(0)} />
                    {/* <Tab sx={{fontSize:15, textTransform:'capitalize'}} label="Item Two" {...a11yProps(1)} />
                    <Tab sx={{fontSize:15, textTransform:'capitalize'}} label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Grid item md={12} sx={{ mt: 2 }}>
                    <MUIDataTable
                        columns={columns}
                        data={tableData}
                        options={options}
                        className="custom-datatable-class" />
                </Grid>                
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={1}>
                Item Two
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                Item Three
            </CustomTabPanel> */}
        </Box>
    );
}

export default Addtaps