import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Box,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Divider,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Arrow from "@mui/icons-material/ArrowBackOutlined";
import InputLabel from "@mui/material/InputLabel";
import {
  AddOutlined,
  Close,
  ExpandMore,
  CloudUpload,
  AddBox,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Delete from "@mui/icons-material/DeleteOutlineOutlined";
import styled from "@emotion/styled";
import { SuggestedActionMenu, SuggestedActionMenuDiv } from "./toggleButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authpostAxios } from "../../Utilities/commonAxios";
// toast
import { toast } from "react-toastify";
import { VariableDropdown } from "./VariableDropdown";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export const CarouselSection = ({
  formik,
  handleDeleteSuggestedAction,
  handleVariableAddition,
}) => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleAddCarouselSection = () => {
    if (formik.values.carouselLists.length >= 10) {
      toast.error(
        "Minimum 2 cards & Maximum 10 cards are required to send Carousel Message!"
      );
    } else {
      formik.setFieldValue("carouselLists", [
        ...formik.values.carouselLists,
        formik.values.carouselLists.findLast((val) => val) + 1,
      ]);
    }
  };
  const handleDeleteCarouselSection = (index) => {
    if (formik.values.carouselLists.length === 2) {
      toast.error(
        "Minimum 2 cards & Maximum 10 cards are required to send Carousel Message!"
      );
    } else {
      formik.setFieldValue(
        "carouselLists",
        formik.values.carouselLists.filter((list) => list !== index)
      );
    }
  };
  return (
    <>
      <Grid item md={6}>
        {formik.values.carouselLists.map((list,index) => {
          return (
            <Accordion key={index} sx={{ mb: 2 }}>
              <AccordionSummary
                sx={{
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#000000ba",
                }}
                expandIcon={<ExpandMore />}
                aria-controls={`CarouselPanel${index}-content`}
                id={`CarouselPanel${index}-header`}
              >
                <Grid container item spacing={1}>
                  <Grid item md="10">
                    Carousel Card
                  </Grid>
                  <Grid item md="1" sx={{ textAlign: "end" }}>
                    <AddBox
                      sx={{ color: "green", fontSize: "20px" }}
                      onClick={handleAddCarouselSection}
                    />
                  </Grid>
                  <Grid item md="1" sx={{ textAlign: "end" }}>
                    <Delete
                      sx={{ color: "red", fontSize: "20px" }}
                      onClick={() => handleDeleteCarouselSection(index)}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <Divider sx={{ border: "1px solid #c8c8c8" }} />
              <AccordionDetails>
                <Grid container item spacing={2} sx={{ marginTop: "1px" }}>
                  <Grid md={12} item sx={{}}>
                    <TextField
                      label="Card Title"
                      id={`carouselDataTitle${[index]}`}
                      defaultValue=""
                      size="small"
                      fullWidth
                      InputLabelProps={{
                        sx: {
                          color: "#92a2b1",
                          fontSize: 15,
                          fontWeight: 400,
                        },
                      }}
                      inputProps={{
                        sx: {
                          color: "#000000de",
                          fontSize: 13,
                          fontWeight: 500,
                        },
                        name:formik.values.carouselDataTitle[index],
                        value:formik.values.carouselDataTitle[index]
                      }}                      
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  {formik.values.templateVariables.length > 0 && (
                    <Grid item md={12}>
                      <VariableDropdown
                        templateVariables={formik.values.templateVariables}
                        handleVariableAddition={handleVariableAddition}
                      />
                    </Grid>
                  )}
                  <Grid md={12} item sx={{}}>
                    <TextField
                      id={`carouselDataDescription${[index]}`}
                      label="Card Description"
                      multiline
                      rows={3}
                      defaultValue=""
                      fullWidth
                      InputLabelProps={{
                        sx: {
                          color: "#92a2b1",
                          fontSize: 15,
                          fontWeight: 400,
                        },
                      }}
                      inputProps={{
                        sx: {
                          color: "#000000de",
                          fontSize: 13,
                          fontWeight: 500,
                        },
                        name:formik.values.carouselDataDescription[index],
                        value:formik.values.carouselDataDescription[index]
                      }}                     
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  {formik.values.templateVariables.length > 0 && (
                    <Grid item md={12}>
                      <VariableDropdown
                        templateVariables={formik.values.templateVariables}
                        handleVariableAddition={handleVariableAddition}
                      />
                    </Grid>
                  )}
                  <Grid md={6} item sx={{}}>
                    <FormControl size="small" fullWidth>
                      <InputLabel
                        id="demo-select-small-label"
                        sx={{
                          color: "#92a2b1",
                          fontSize: 15,
                          fontWeight: 400,
                        }}
                      >
                        Width
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={age}
                        label="Width"
                        onChange={handleChange}
                        sx={{
                          color: "#000000de",
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Text</MenuItem>
                        <MenuItem value={20}>Rich Card</MenuItem>
                        <MenuItem value={30}>Carousel</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={6} item sx={{}}>
                    <FormControl size="small" fullWidth>
                      <InputLabel
                        id="demo-select-small-label"
                        sx={{
                          color: "#92a2b1",
                          fontSize: 15,
                          fontWeight: 400,
                        }}
                      >
                        Height
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={age}
                        label="Height"
                        onChange={handleChange}
                        sx={{
                          color: "#000000de",
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Text</MenuItem>
                        <MenuItem value={20}>Rich Card</MenuItem>
                        <MenuItem value={30}>Carousel</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={12} item sx={{}}>
                    <FormControl size="small" fullWidth>
                      <InputLabel
                        id="demo-select-small-label"
                        sx={{
                          color: "#92a2b1",
                          fontSize: 15,
                          fontWeight: 400,
                        }}
                      >
                        Media Type
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={age}
                        label="Media Type"
                        onChange={handleChange}
                        sx={{
                          color: "#000000de",
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Text</MenuItem>
                        <MenuItem value={20}>Rich Card</MenuItem>
                        <MenuItem value={30}>Carousel</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} sx={{ mb: 1 }}>
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUpload />}
                      fullWidth
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </Grid>
                  <Grid md={12} item sx={{}}>
                    <SuggestedActionMenu formik={formik} />
                  </Grid>
                  <Grid item md={12}>
                    {formik.values.selectedSuggestedActions.map(
                      (action, index) => (
                        <SuggestedActionMenuDiv
                          actionId={action}
                          index={index}
                          handleDeleteSuggestedAction={
                            handleDeleteSuggestedAction
                          }
                        />
                      )
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
        )}
        )}
      </Grid>
    </>
  );
};
