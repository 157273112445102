import {
    Container, Grid, TextField, Select, FormControl, InputLabel, MenuItem, Typography, Modal, Box, Tabs, Tab, Autocomplete, Checkbox, Divider, Button, FormControlLabel, RadioGroup, Radio,
    FilledInput, InputAdornment, Dialog, Tooltip, DialogTitle, DialogContent, DialogContentText, IconButton, DialogActions, FormGroup, Card
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Link, useParams } from "react-router-dom";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import android from '../../Images/android-view-layout.png';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CheckBox, Label, ReportProblem } from "@mui/icons-material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-emoji/dist/quill-emoji.js';
import { useFormik } from 'formik';
import { authpostAxios } from "../../Utilities/commonAxios";
// toast
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { fileToBase64 } from '../../Utilities/fileConversion';
// import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import _ from "lodash";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Circles, ThreeDots } from 'react-loader-spinner';
import Battery from '@mui/icons-material/BatteryChargingFull';
import ArrowBackleft from '@mui/icons-material/ArrowBackOutlined';
import Landlineicon from '@mui/icons-material/CallOutlined';
import Outline from '@mui/icons-material/MoreVertOutlined';
import LoginImage from '../../Images/profile-picture.jpg';
import Documentimg from '../../Images/documentimg.jpg';
import LinkcopyIcon from '@mui/icons-material/LaunchOutlined';
import Formlisticon from '@mui/icons-material/FormatListBulleted';
import moment from 'moment';
import { useDispatch } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const top100Films = [
    { title: 'English', value: 1 },
    { title: 'Tamil', value: 2 },
]

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    padding: '10px',
    placeItems: 'center',
    color: '#000',
    fontWeight: 500
};

const img = {
    display: 'block',
    width: '100%',
    height: '80px',
};


const ql_editor = {
    height: '180px'
};

const editorContent = {
    overflow: 'visible',
};

const loaderOverlay = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};
const appcontainer = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};


const cardBorderstyle = {
    border: '4px solid #3a4245',
    borderRadius: '10px',
    position: 'fixed',
    top: '85px',
    width: '20%'
};



const headerTopstyle = {
    backgroundColor: '#054d47',
    height: '15px',
};

const bottomHeader = {
    backgroundColor: '#085b53',
    height: '30px',
    paddingTop: '2px',
};

const messageBody = {
    backgroundColor: '#dfd7cf',
    padding: '10px',
    height: '435px',
};

const messageBox = {
    backgroundColor: '#fff',
    borderRadius: '5px',
    position: 'relative',
};

// const boxHeightstyle={
//     maxHeight: '330px', overflowY: 'scroll',overflowX: 'hidden',
// }

const innermsg = {
    borderTop: '5px solid #fff',
    borderRight: '8px solid transparent',
    borderLeft: '8px solid transparent',
    position: 'absolute',
    left: '-5px',
    top: '1px',
    transform: 'matrix(1, -1, 1, 1, 0, 0) rotate(45deg)',
};

const CreateCarouselTemplate = () => {
    const dispatch = useDispatch()
    const [isTemplateSubmitted, setIsTemplateSubmitted] = useState(false)
    const { AppId } = useParams();
    const toolbarOptions1 = [
        ['bold', 'italic', 'underline'],
        // ['blockquote', 'code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],
        // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],
        // [{ 'indent': '-1' }, { 'indent': '+1' }],
        // [{ 'direction': 'rtl' }],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        // [{ 'color': [] }, { 'background': [] }],
        // [{ 'font': [] }],
        // [{ 'align': [] }],
        // ['clean'],
        ['emoji'], // Add an emoji button
    ];

    // Modal - sample content
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    };
    // Modal - sample warning
    const BootstrapPopupDialog = styled(Dialog)();
    const [popup, setPopup] = useState(false);
    const handlePopupClose = () => {
        setPopup(false)
        setOpen(false)
    };
    const handlePopupToggle = () => {
        setPopup(false)
        setOpen(true)
    }
    const navigate = useNavigate();
    const submitTemplateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            template_name: '',
            app_id: '',
            WhatsappCategoryMode: '',
            language_code: '',
            media_type: '1',
            header_text: '',
            headerMedia: '',
            headerMediaExistPath: '',
            editorContent: '',
            template_footer: '',
            headerSample: '',
            bodySample: [],
            ButtonSample: '',
            add_website_url_checkbox: false,
            quick_replay_checkbox: false,
            add_phone_number_checkbox: false,
            footer_button_url_type: [],
            footer_button_url_link: [],
            footer_button_url_text: [],
            footer_button_phcode: '',
            footer_button_phno: '',
            footer_button_phno_text: '',
            quick_reply: [],
            button_option: ''
        },
        validationSchema: Yup.object({
            template_name: Yup.string().required('Please enter template name'),
            WhatsappCategoryMode: Yup.string().required('Please select category'),
            language_code: Yup.string().required('Please select language'),
            editorContent: Yup.string().required('Please enter body content')
        }),
        onSubmit: async (values) => {
            if (!submitTemplateForm.dirty) {
                toast.error('No data has changed!')
            } else {
                if ((_.some(_.compact(values.footer_button_url_link), (str) => str.indexOf('{{', 0) !== -1) && values.ButtonSample === "") || (values.header_text.includes('}}') && values.headerSample === "") || ((_.compact(values.bodySample)).length !== (values.editorContent.match(/}}/g) || []).length)) {
                    setPopup(true)
                } else {
                    await setIsTemplateSubmitted(true)
                    let inputData = {
                        "template_name": ((values.template_name).trim()).replaceAll(' ', '_'),
                        "app_id": atob(AppId),
                        "WhatsappCategoryMode": values.WhatsappCategoryMode,
                        "language_code": values.language_code,
                        "media_type": values.media_type,
                        "header_text": values.header_text,
                        "headerMedia": (values.headerMedia === "") ? ("") : (await fileToBase64(values.headerMedia)),
                        "headerMediaExistPath": values.headerMediaExistPath,
                        "editorContent": values.editorContent,
                        "template_footer": values.template_footer,
                        "headerSample": values.headerSample,
                        "bodySample": _.compact(values.bodySample),
                        "ButtonSample": values.ButtonSample,
                        "button_option": buttonCheckbox,
                        "add_website_url_checkbox": websiteUrlCheckbox,
                        "add_phone_number_checkbox": phoneNumberCheckbox,
                        "quick_replay_checkbox": quickReplyCheckbox,
                        "footer_button_url_type": ((buttonCheckbox && websiteUrlCheckbox) ? (_.compact(values.footer_button_url_type)) : ([])),
                        "footer_button_url_link": ((buttonCheckbox && websiteUrlCheckbox) ? (_.compact(values.footer_button_url_link)) : ([])),
                        "footer_button_url_text": ((buttonCheckbox && websiteUrlCheckbox) ? (_.compact(values.footer_button_url_text)) : ([])),
                        "footer_button_phcode": ((buttonCheckbox && phoneNumberCheckbox) ? (values.footer_button_phcode) : ('')),
                        "footer_button_phno": ((buttonCheckbox && phoneNumberCheckbox) ? (values.footer_button_phno) : ('')),
                        "footer_button_phno_text": ((buttonCheckbox && phoneNumberCheckbox) ? (values.footer_button_phno_text) : ('')),
                        "quick_reply": ((buttonCheckbox && quickReplyCheckbox) ? (_.compact(values.quick_reply)) : ([]))
                    }
                    let { data: usercompanyResponse } = await authpostAxios(
                        {
                            'url': `${process.env.REACT_APP_BACKENDURL}api/template-create`,
                            'data': inputData
                        },dispatch)
                    await setIsTemplateSubmitted(false)
                    if (usercompanyResponse.flag === 1) {
                        toast.success(usercompanyResponse.message)
                        navigate(`/app-view/${AppId}`)
                    } else {
                        toast.error(usercompanyResponse.message)
                    }
                }
            }
        }
    });

    const [websiteUrlCheckbox, setWebsiteUrlCheckbox] = useState(false);
    const handleWebsiteUrlCheckbox = () => {
        setWebsiteUrlCheckbox(!websiteUrlCheckbox);
    };

    const [phoneNumberCheckbox, setPhoneNumberCheckbox] = useState(false);
    const handlePhoneNumberCheckbox = () => {
        setPhoneNumberCheckbox(!phoneNumberCheckbox);
    };

    const [quickReplyCheckbox, setQuickReplyCheckbox] = useState(false);
    const handleQuickReplyCheckbox = () => {
        setQuickReplyCheckbox(!quickReplyCheckbox);
    };

    const [buttonCheckbox, setButtonCheckbox] = useState(false);
    const handleButtonCheckbox = () => {
        setButtonCheckbox(!buttonCheckbox);
    };

    /* WHATSAPP CATEGORY */
    const [whatsappCategory, setWhatsappcategory] = useState([]);
    const getwhatsappCategoryFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-whatsappcategory`,
                'data': ''
            },dispatch).then(whatsappCategoryResponse => setWhatsappcategory(whatsappCategoryResponse.data?.data))

    }

    /* LANGUAGE MASTER */
    const [templateLanguage, setTemplatelanguage] = useState([]);
    const getlanguageFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-template-language`,
                'data': ''
            },dispatch).then(templatelanguageResponse => setTemplatelanguage(templatelanguageResponse.data?.data))
    }

    /* Website Type */
    const [websiteTypes, setWebsiteTypes] = useState([]);
    const getWebsiteTypeFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-website-type`,
                'data': ''
            },dispatch).then(websiteTypeResponse => setWebsiteTypes(websiteTypeResponse.data?.data))
    }

    /* Country code */
    const [countryCodes, setCountryCodes] = useState([]);
    const getCountryCodeFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-country-code`,
                'data': ''
            },dispatch).then(countryCodeResponse => setCountryCodes(countryCodeResponse.data?.data))
    }

    const [websiteUrlFields, setWebsiteUrlFields] = useState([{ id: 1, value: '' }]);
    const handleAddWebsiteUrlFields = () => {
        const newField = { id: websiteUrlFields.length + 1, value: '' };
        if (websiteUrlFields.length < 2) {
            setWebsiteUrlFields([...websiteUrlFields, newField]);
        }
    };

    const handleRemoveWebsiteUrlFields = (id) => {
        const updatedWebsiteUrlFields = websiteUrlFields.filter((field) => field.id !== id);
        setWebsiteUrlFields(updatedWebsiteUrlFields);
        const fieldName1 = `footer_button_url_type[${id}]`;
        submitTemplateForm.setFieldValue(fieldName1, '');

        const fieldName2 = `footer_button_url_link[${id}]`;
        submitTemplateForm.setFieldValue(fieldName2, '');

        const fieldName3 = `footer_button_url_text[${id}]`;
        submitTemplateForm.setFieldValue(fieldName3, '');
        if (websiteUrlFields.length === 1) setWebsiteUrlCheckbox(false)
    };

    const [quickReplyFields, setQuickReplyFields] = useState([{ id: 1, value: '' }]);
    const handleAddQuickReplyFields = () => {
        const newField = { id: quickReplyFields.length + 1, value: '' };
        if (quickReplyFields.length < 2) {
            setQuickReplyFields([...quickReplyFields, newField]);
        }
    };

    const handleRemoveQuickReplyFields = (id) => {
        const updatedQuickReplyFields = quickReplyFields.filter((field) => field.id !== id);
        setQuickReplyFields(updatedQuickReplyFields);
        const fieldName1 = `quick_reply[${id}]`;
        submitTemplateForm.setFieldValue(fieldName1, '');
        if (quickReplyFields.length === 1) setQuickReplyCheckbox(false)
    };

    const [editorValue, setEditorValue] = useState('');
    useEffect(() => {
        getwhatsappCategoryFromAPI()
        getlanguageFromAPI()
        getWebsiteTypeFromAPI()
        getCountryCodeFromAPI()
    }, []);

    const handleEditorChange = (content) => {
        setEditorValue(content);
        submitTemplateForm.setFieldValue('editorContent', content);
    };
    // header variable
    const [headerVariableCount, setHeaderVariableCount] = useState(1);
    const addHeaderVariable = () => {
        let matchVariableText;
        let variablePattern = submitTemplateForm.values.header_text.match(/{{([^}]+)}}/g);
        matchVariableText = variablePattern ? parseInt((variablePattern[variablePattern.length - 1]).replace(/[{}]/g, '')) : 0;
        if (matchVariableText === 0) {
            setHeaderVariableCount(matchVariableText + 1)
            // Get the current cursor position
            let headerTextElement = document.getElementById("header_text");
            const position = headerTextElement.selectionStart;
            // Get the text before and after the cursor position
            const before = headerTextElement.value.substring(0, position);
            const after = headerTextElement.value.substring(position, headerTextElement.value.length);
            // Insert the new text at the cursor position
            submitTemplateForm.values.header_text = before + `{{${headerVariableCount}}}` + after;
            headerTextElement.selectionStart = headerTextElement.selectionEnd = headerTextElement.value.length;
            headerTextElement.focus();
        } else {
            toast.error('Only one variable is allowed to add in the header!')
        }
    }
    // body variable
    const [bodyVariableStart, setBodyVariableStart] = useState(0);
    const quillRef = React.useRef(null);
    const addBodyVariable = () => {
        let matchVariableText;
        if (bodyVariableStart === 1) {
            let variablePattern = editorValue.match(/{{([^}]+)}}/g);
            matchVariableText = (variablePattern) ? (parseInt((variablePattern[variablePattern.length - 1]).replace(/[{}]/g, '')) + 1) : (1);
        } else {
            matchVariableText = 1;
        }
        const selection = quillRef.current.getEditor().getSelection();
        quillRef.current.getEditor().insertText(selection ? selection.index : 0, `{{${matchVariableText}}}`);
        quillRef.current.getEditor().setSelection(quillRef.current.getEditor().getLength(), 0);
        setBodyVariableStart(1)
    }
    // sample button enable
    const [sampleButton, SetSampleButton] = useState(false);
    useEffect(() => {
        if ((_.some(_.compact(submitTemplateForm.values.footer_button_url_link), (str) => str.indexOf('{{', 0) !== -1)) || submitTemplateForm.values.header_text.includes('}}') || (quillRef.current && quillRef.current.getEditor().getText().includes('}}'))) SetSampleButton(true)
        else SetSampleButton(false)
    }, [submitTemplateForm.values.header_text, quillRef?.current?.getEditor().getText(), submitTemplateForm.values.footer_button_url_link])
    // header preview - validation
    useEffect(() => {
        submitTemplateForm.setFieldValue('headerMedia', '')
        submitTemplateForm.setFieldValue('header_text', '')
    }, [submitTemplateForm.values.media_type])
    // total checkbox count
    let totalCheckedBoxCount = 0;
    const [totalUpdatedCheckedBoxCount, setTotalUpdatedCheckedBoxCount] = useState(0);
    let footerFieldsData = []
    const [updatedfooterFieldsData, setUpdatedfooterFieldsData] = useState([])
    const [combinedFooter, setCombinedFooter] = useState('')
    useEffect(() => {
        footerFieldsData = [];
        let footer_button_url_text_array = _.compact(submitTemplateForm.values.footer_button_url_text)
        let quick_reply_array = _.compact(submitTemplateForm.values.quick_reply)
        if (buttonCheckbox && websiteUrlCheckbox) {
            totalCheckedBoxCount += websiteUrlFields.length;
            websiteUrlFields.map((websiteUrlField, key) => {
                footerFieldsData.push({ 'type': 'url', 'text': (footer_button_url_text_array[key]) ?? '' })
            })
        }
        if (buttonCheckbox && phoneNumberCheckbox) {
            totalCheckedBoxCount += 1;
            footerFieldsData.push({ 'type': 'phone', 'text': submitTemplateForm.values.footer_button_phno_text })
        }
        if (buttonCheckbox && quickReplyCheckbox) {
            totalCheckedBoxCount += quickReplyFields.length;
            websiteUrlFields.map((websiteUrlField, key) => {
                footerFieldsData.push({ 'type': 'reply', 'text': (quick_reply_array[key]) ?? '' })
            })
        }
        setTotalUpdatedCheckedBoxCount(totalCheckedBoxCount)
        setUpdatedfooterFieldsData(footerFieldsData)
    }, [buttonCheckbox, websiteUrlCheckbox, websiteUrlFields, submitTemplateForm.values.footer_button_url_text, quickReplyCheckbox, quickReplyFields, submitTemplateForm.values.quick_reply, phoneNumberCheckbox, submitTemplateForm.values.footer_button_phno_text])
    useEffect(() => {
        if (totalUpdatedCheckedBoxCount > 0) {
            let FooterData = updatedfooterFieldsData.map((data, key) => (
                <>
                    {(key <= 1) ? (
                        <>
                            <Divider />
                            <Grid sx={{ padding: '3px' }} key={key}>
                                <Grid item md={12} sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ color: '#0096de', fontSize: '13px', fontWeight: '500', minHeight: '25px' }}>
                                        {data.type === 'url' && (
                                            <>
                                                <LinkcopyIcon sx={{ color: '#0096de', fontSize: '16px' }} /> {data.text}
                                            </>
                                        )}
                                        {data.type === 'phone' && (
                                            <>
                                                <Landlineicon sx={{ color: '#0096de', fontSize: '16px' }} /> {data.text}
                                            </>
                                        )}
                                        {(data.type === 'reply') && (
                                            <>
                                                {data.text}
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        (key === 2) ? (<>
                            <Divider />
                            <Grid sx={{ padding: '3px' }} key={key}>
                                <Grid item md={12} sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ color: '#0096de', fontSize: '13px', fontWeight: '500' }}>
                                        <Formlisticon sx={{ color: '#0096de', fontSize: '16px' }} /> {`See all options`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>) : ('')
                    )}
                </>
            ))
            setCombinedFooter(FooterData)
        }
    }, [totalUpdatedCheckedBoxCount, updatedfooterFieldsData])
    return (
        <>
            {(!isTemplateSubmitted ? (
                <>
                    <form onSubmit={submitTemplateForm.handleSubmit} >
                        <Grid sx={{ mt: 5, mb: 5 }}>
                            <Container>
                                <Grid container>
                                    <Grid item md={12}>
                                        <Typography sx={{ color: '#32393a', fontSize: 20, fontWeight: 500,mb:2 }}>
                                            Create Template
                                        </Typography>
                                       
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item md={3}>
                                        <TextField id="template_name" onChange={submitTemplateForm.handleChange} onBlur={submitTemplateForm.handleBlur} value={submitTemplateForm.values.template_name} size="small" label="Template Name" name="template_name" variant="outlined" fullWidth />
                                        <Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitTemplateForm.errors.template_name}</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormControl fullWidth>
                                            <InputLabel size="small" id="WhatsappCategoryMode">Category</InputLabel>
                                            <Select size="small" name="WhatsappCategoryMode" labelId="WhatsappCategoryMode" label="Category" value={submitTemplateForm.values.WhatsappCategoryMode} onChange={submitTemplateForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} >
                                                {whatsappCategory ? (whatsappCategory.map((whatsappCategory, key) => (
                                                    <MenuItem value={whatsappCategory.categoryid} key={key}>{whatsappCategory.category_name}</MenuItem>
                                                ))) : null}
                                            </Select>
                                        </FormControl>
                                        {(submitTemplateForm.errors.WhatsappCategoryMode) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitTemplateForm.errors.WhatsappCategoryMode}</Typography>) : (null)}
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormControl fullWidth>
                                            <InputLabel size="small" id="language_code">Language</InputLabel>
                                            <Select size="small" name="language_code" labelId="Language" label="Language" value={submitTemplateForm.values.language_code} onChange={submitTemplateForm.handleChange} fullWidth sx={{ '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: '#000000de', fontSize: 13, fontWeight: 500 } }} >
                                                {templateLanguage ? (templateLanguage.map((templateLanguage, key) => (
                                                    <MenuItem value={templateLanguage.template_language_code} key={key}>{templateLanguage.template_language_name}</MenuItem>
                                                ))) : null}
                                            </Select>
                                        </FormControl>
                                        {(submitTemplateForm.errors.language_code) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitTemplateForm.errors.language_code}</Typography>) : (null)}
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                        <Container>
                            <Grid container spacing={2} sx={{}}>
                                <Grid item md={9}>
                                    <Grid container>
                                        <Grid item md={6} sx={{ alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                                Template for English Language
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} sx={{ textAlign: 'end' }}>
                                            <Button variant="contained" onClick={handleClickOpen}
                                                disabled={(sampleButton === true) ? (false) : (true)}
                                            >Add Sample</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset', p: 3, mt: 3, borderRadius: 1, mb: 2 }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 2 }}>
                                            Header (Optional)
                                        </Typography>
                                        <Typography sx={{ fontSize: '15px', fontWeight: 400 }}>
                                            Add a title, or, select the media type you want to get approved for this template's header
                                        </Typography>
                                        <Grid style={{ width: '100%', marginTop: '10px', mb: 3 }}>
                                            <FormControl>
                                                <RadioGroup row defaultValue="none" name="radio_buttons_group" sx={{ "& .MuiFormControlLabel-label": { fontWeight: 500, mr: 2, fontSize: 14 } }}>
                                                    <FormControlLabel value="1" id="none" name="media_type" control={<Radio />} label="None" checked={submitTemplateForm.values.media_type === "1"} onChange={submitTemplateForm.handleChange} />
                                                    <FormControlLabel value="2" id="text" name="media_type" control={<Radio />} label="Text" checked={submitTemplateForm.values.media_type === "2"} onChange={submitTemplateForm.handleChange} />
                                                    <FormControlLabel value="3" id="image" name="media_type" control={<Radio />} label="Image" checked={submitTemplateForm.values.media_type === "3"} onChange={submitTemplateForm.handleChange} />
                                                    <FormControlLabel value="4" id="video" name="media_type" control={<Radio />} label="Video" checked={submitTemplateForm.values.media_type === "4"} onChange={submitTemplateForm.handleChange} />
                                                    <FormControlLabel value="5" id="document" name="media_type" control={<Radio />} label="Document" checked={submitTemplateForm.values.media_type === "5"} onChange={submitTemplateForm.handleChange} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid container className="textfield" sx={{ mt: 3, display: ((submitTemplateForm.values.media_type === '2') ? ('block') : 'none') }}>
                                            <Grid item md={12}>
                                                <FormControl variant="outlined" size="small" fullWidth>
                                                    <FilledInput
                                                        id="header_text"
                                                        name="header_text"
                                                        onChange={submitTemplateForm.handleChange} onBlur={submitTemplateForm.handleBlur} value={submitTemplateForm.values.header_text}
                                                        endAdornment={<InputAdornment position="end">0/60</InputAdornment>}
                                                        aria-describedby="filled-weight-helper-text"
                                                        inputProps={{
                                                            'aria-label': 'weight',
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={12} sx={{ textAlign: 'end', mt: 1 }}>
                                                <Link style={{ textDecoration: 'none', color: '#16a6fb', fontWeight: 500, }}><AddCircleOutlinedIcon style={{ fontSize: 18 }} />
                                                    <span style={{ marginLeft: '5px', marginRight: '5px' }} onClick={addHeaderVariable}>Add variable</span>
                                                    <Tooltip title="These make the content of the message personalized for users" placement="top-start" arrow>
                                                        <InfoOutlinedIcon style={{ fontSize: 17, }} />
                                                    </Tooltip>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="imagefield" sx={{ mt: 3, mb: 1, display: ((submitTemplateForm.values.media_type === '3') ? ('block') : 'none') }}>
                                            <Grid sx={{}}>
                                                <input
                                                    type="file"
                                                    name="headerImage"
                                                    id="headerImage"
                                                    style={{ display: 'none' }}
                                                    accept="image/jpg,image/jpeg,image/png"
                                                    onChange={
                                                        (e) => {
                                                            submitTemplateForm.setFieldValue('headerMedia', e.currentTarget.files[0])
                                                        }
                                                    }
                                                />
                                                <Button
                                                    component="label"
                                                    htmlFor="headerImage"
                                                    fullWidth variant="outlined" style={{ border: '1px solid #cfd7df', color: '#92a2b1', textTransform: 'capitalize', fontSize: '15px' }} startIcon={<CloudUploadIcon />}
                                                    onChange={
                                                        (e) => {
                                                            submitTemplateForm.setFieldValue('headerMedia', e.currentTarget.files[0])
                                                        }
                                                    }
                                                >
                                                    Choose JPG OR PNG file
                                                </Button>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Typography sx={{ fontSize: 15, mt: 1 }}>
                                                    This media file will be sent as a sample to WhatsApp, for approval. At the time of sending the template, you can change the media file, if required. You can also send separate media files for each customer in a campaign.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="videofield" sx={{ mt: 3, mb: 1, display: ((submitTemplateForm.values.media_type === '4') ? ('block') : 'none') }}>
                                            <Grid sx={{}}>
                                                <input
                                                    type="file"
                                                    name="headerVideo"
                                                    id="headerVideo"
                                                    style={{ display: 'none' }}
                                                    accept="video/mp4"
                                                    onChange={
                                                        (e) => {
                                                            submitTemplateForm.setFieldValue('headerMedia', e.currentTarget.files[0])
                                                        }
                                                    }
                                                />
                                                <Button
                                                    component="label"
                                                    htmlFor="headerVideo"
                                                    fullWidth variant="outlined" style={{ border: '1px solid #cfd7df', color: '#92a2b1', textTransform: 'capitalize', fontSize: '15px' }} startIcon={<CloudUploadIcon />}
                                                    onChange={
                                                        (e) => {
                                                            submitTemplateForm.setFieldValue('headerMedia', e.currentTarget.files[0])
                                                        }
                                                    }
                                                >
                                                    Choose MP4 Video file
                                                </Button>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Typography sx={{ fontSize: 15, mt: 1 }}>
                                                    This media file will be sent as a sample to WhatsApp, for approval. At the time of sending the template, you can change the media file, if required. You can also send separate media files for each customer in a campaign.
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container className="pdfsection" sx={{ mt: 3, mb: 1, display: ((submitTemplateForm.values.media_type === '5') ? ('block') : 'none') }}>
                                            <Grid sx={{}}>
                                                <input
                                                    type="file"
                                                    name="headerDocument"
                                                    id="headerDocument"
                                                    style={{ display: 'none' }}
                                                    accept="application/pdf"
                                                    onChange={
                                                        (e) => {
                                                            submitTemplateForm.setFieldValue('headerMedia', e.currentTarget.files[0])
                                                        }
                                                    }
                                                />
                                                <Button
                                                    component="label"
                                                    htmlFor="headerDocument"
                                                    fullWidth variant="outlined" style={{ border: '1px solid #cfd7df', color: '#92a2b1', textTransform: 'capitalize', fontSize: '15px' }} startIcon={<CloudUploadIcon />}
                                                    onChange={
                                                        (e) => {
                                                            submitTemplateForm.setFieldValue('headerMedia', e.currentTarget.files[0])
                                                        }
                                                    }
                                                >
                                                    Choose PDF file
                                                </Button>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Typography sx={{ fontSize: 15, mt: 1 }}>
                                                    This media file will be sent as a sample to WhatsApp, for approval. At the time of sending the template, you can change the media file, if required. You can also send separate media files for each customer in a campaign.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {submitTemplateForm.values.headerMedia && (
                                            <Grid sx={thumb}>
                                                {(submitTemplateForm.values.media_type === '3' && submitTemplateForm.values.headerMedia) && (
                                                    <img src={URL.createObjectURL(submitTemplateForm.values.headerMedia)} style={img} />
                                                )}
                                                {(submitTemplateForm.values.media_type === '4' && submitTemplateForm.values.headerMedia) && (
                                                    <video src={URL.createObjectURL(submitTemplateForm.values.headerMedia)} style={img} />
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid sx={{ boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset', p: 3, mt: 3, borderRadius: 1, mb: 2, height: '355px' }}>
                                        <Grid container>
                                            <Grid item md={12}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 2 }}>
                                                    Body
                                                </Typography>
                                                <Typography sx={{ fontSize: '15px', fontWeight: 400 }}>
                                                    The Whatsapp message in the language you have selected
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item md={12} sx={{ mt: 3, }}>
                                                <ReactQuill
                                                    ref={quillRef}
                                                    id="editorContent"
                                                    name="editorContent"
                                                    value={editorValue}
                                                    onChange={handleEditorChange}
                                                    style={ql_editor}
                                                    theme="snow"
                                                    modules={{
                                                        toolbar: {
                                                            container: toolbarOptions1,
                                                        },
                                                        'emoji-toolbar': true, // Enable the emoji module
                                                        // 'emoji-textarea': true, // Enable emoji input in the editor
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {(submitTemplateForm.errors.editorContent) ? (<Typography variant="body2" sx={{ color: 'red', fontSize: 14 }}>{submitTemplateForm.errors.editorContent}</Typography>) : (null)}
                                    <Grid item md={12} sx={{ mt: 1, }}>
                                        <Link style={{ textDecoration: 'none', color: '#16a6fb', fontWeight: 500, }}><AddCircleOutlinedIcon style={{ fontSize: 18 }} />
                                            <span onClick={addBodyVariable} style={{ marginLeft: '5px', marginRight: '5px' }}>Add variable</span>
                                            <Tooltip title="These make the content of the message personalized for users" placement="top-start" arrow>
                                                <InfoOutlinedIcon style={{ fontSize: 17, }} />
                                            </Tooltip>
                                        </Link>
                                    </Grid>


                                    <Grid sx={{ boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset', p: 3, mt: 3, borderRadius: 1, mb: 2 }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 2 }}>
                                            Footer (Optional)
                                        </Typography>
                                        <Typography sx={{ fontSize: '15px', fontWeight: 400 }}>
                                            Add a short line of text to the bottom of your message template.
                                        </Typography>
                                        <Grid container>
                                            <Grid item md={12} sx={{ mt: 3, }}>
                                                <FormControl variant="outlined" size="small" fullWidth>
                                                    <FilledInput
                                                        onChange={submitTemplateForm.handleChange} onBlur={submitTemplateForm.handleBlur} value={submitTemplateForm.values.template_footer}
                                                        id="template_footer"
                                                        name="template_footer"
                                                        endAdornment={<InputAdornment position="end">0/60</InputAdornment>}
                                                        aria-describedby="filled-weight-helper-text"
                                                        inputProps={{
                                                            'aria-label': 'weight',
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    {/*  NEW FOOTER DESIGN  */}
                                    {/*  END FOOTER DESIGN  */}

                                    <Grid sx={{ boxShadow: '1px 1px 8px rgb(0 0 0 / 17%)', border: 'unset', p: 3, mt: 3, borderRadius: 1, mb: 2 }}>
                                        <Grid item md={12}>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                                                <FormControlLabel name="button_option" control={<Checkbox checked={buttonCheckbox} onChange={handleButtonCheckbox} />} label="Buttons  (Optional)" />
                                            </Typography>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormGroup sx={{ display: (buttonCheckbox ? ('block') : ('none')) }}>
                                                <hr />
                                                <FormControlLabel name="add_website_url_checkbox" control={<Checkbox checked={websiteUrlCheckbox} onChange={handleWebsiteUrlCheckbox} />} label="Add Website URL" />
                                            </FormGroup>
                                        </Grid>
                                        {buttonCheckbox && websiteUrlCheckbox && (
                                            <>
                                                <Grid container>
                                                    {websiteUrlFields.map((field, index) => (
                                                        <React.Fragment key={field.id}>
                                                            <Grid item md={12} key={field.id} className="quicksection" style={{ marginTop: '10px', mb: 4, border: '1px solid #ddd', borderRadius: 10, padding: '10px' }}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item md={3} >
                                                                        <FormControl sx={{}} size="small" fullWidth>
                                                                            <InputLabel id={`select-label-${field.id}`}>Select</InputLabel>
                                                                            <Select onChange={submitTemplateForm.handleChange}
                                                                                labelId={`select-label-${field.id}`}
                                                                                name={`footer_button_url_type[${field.id}]`}
                                                                                label={`Select`}
                                                                            >
                                                                                {websiteTypes ? (websiteTypes.map((websiteType, key) => (
                                                                                    <MenuItem value={websiteType.wa_template_url_id} key={key}>{websiteType.wa_template_url_name}</MenuItem>
                                                                                ))) : null}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <FormControl variant="outlined" size="small" fullWidth>
                                                                            <FilledInput
                                                                                sx={{ height: '40px' }}
                                                                                endAdornment={<InputAdornment position="end">0/25</InputAdornment>}
                                                                                aria-describedby="filled-weight-helper-text"
                                                                                name={`footer_button_url_link[${field.id}]`}
                                                                                onChange={submitTemplateForm.handleChange} onBlur={submitTemplateForm.handleBlur}
                                                                                inputProps={{
                                                                                    'aria-label': 'weight',
                                                                                    placeholder: 'Enter url, example: https://example.com/test'
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item md={1} sx={{ alignItems: 'center', display: 'grid', textAlign: 'end' }}>
                                                                        <Link onClick={() => handleRemoveWebsiteUrlFields(field.id)}>
                                                                            <RemoveCircleIcon style={{ color: 'red' }} />
                                                                        </Link>
                                                                    </Grid>
                                                                    <Grid item md={12}>
                                                                        <FormControl variant="outlined" size="small" fullWidth>
                                                                            <FilledInput
                                                                                id={`footer_button_url_text${field.id}`}
                                                                                sx={{ height: '40px' }}
                                                                                name={`footer_button_url_text[${field.id}]`}
                                                                                onChange={submitTemplateForm.handleChange}
                                                                                onBlur={submitTemplateForm.handleBlur}
                                                                                aria-describedby="filled-weight-helper-text"
                                                                                inputProps={{
                                                                                    'aria-label': 'weight',
                                                                                    placeholder: 'Enter text for the button'
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    {(submitTemplateForm.values.footer_button_url_type[field.id] === 2) ? (
                                                                        <>
                                                                            <Grid item md={12}>
                                                                                <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                                                                                    There are 2 ways of providing the dynamic URL:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item md={12}>
                                                                                <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                                                                                    {`1. Dynamic portion of URL as a variable - The provided URL should have a variable in place of the dynamic portion of the URL. If your actual URL is example.com/order/12345 where 12345 is the dynamic part, you should enter the URL as example.com/order/{{1}}. You can map {{1}} to a user trait / event trait which contains the dynamic portion for each customer.`}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item md={12}>
                                                                                <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                                                                                    {`2. Full URL as a variable - Provide the dynamic URL as https://example.com/test?redirect={{1}}. You can map {{1}} to a user trait / event trait, which contains the full URL for each customer.`}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </>
                                                                    ) : ('')}
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                                {
                                                    (websiteUrlFields.length >= 2) ? ('') : (
                                                        <>
                                                            <Grid container sx={{ mt: 2 }}>
                                                                <Link onClick={handleAddWebsiteUrlFields} style={{ textDecoration: 'none', fontSize: '14px', fontWeight: 500 }}><AddCircleIcon /> Add another website</Link>
                                                            </Grid>
                                                        </>)
                                                }
                                            </>
                                        )}
                                        <Grid spacing={2}>
                                            <FormGroup sx={{ display: (buttonCheckbox ? ('block') : ('none')) }}>
                                                <FormControlLabel name="add_phone_number_checkbox" control={<Checkbox checked={phoneNumberCheckbox} onChange={handlePhoneNumberCheckbox} />} label="Add Phone Number" />
                                            </FormGroup>
                                        </Grid>
                                        {buttonCheckbox && phoneNumberCheckbox && (
                                            <Grid item md={12} className="quicksection" style={{ marginTop: '10px', mb: 4, border: '1px solid #ddd', borderRadius: 10, padding: '10px' }}>
                                                <Grid container spacing={2} >
                                                    <Grid item md={3}>
                                                        <FormControl sx={{}} size="small" fullWidth>
                                                            <InputLabel id="demo-select-small-label">Select</InputLabel>
                                                            <Select
                                                                onChange={submitTemplateForm.handleChange}
                                                                labelId="demo-select-small-label"
                                                                name="footer_button_phcode"
                                                                label="Select"
                                                            >
                                                                {countryCodes ? (countryCodes.map((countryCode, key) => (
                                                                    <MenuItem value={_.replace(countryCode.ph_code, '+', '')} key={key}>{countryCode.ph_code}</MenuItem>
                                                                ))) : null}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                        <FormControl variant="outlined" size="small" fullWidth>
                                                            <FilledInput
                                                                sx={{ height: '40px' }}
                                                                endAdornment={<InputAdornment position="end">0/25</InputAdornment>}
                                                                aria-describedby="filled-weight-helper-text"
                                                                name="footer_button_phno"
                                                                onChange={submitTemplateForm.handleChange} onBlur={submitTemplateForm.handleBlur}
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                    placeholder: 'Enter the phone number'
                                                                }} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <FormControl variant="outlined" size="small" fullWidth>
                                                            <FilledInput
                                                                id="footer_button_phno_text"
                                                                sx={{ height: '40px' }}
                                                                name="footer_button_phno_text"
                                                                onChange={submitTemplateForm.handleChange} onBlur={submitTemplateForm.handleBlur}
                                                                aria-describedby="filled-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                    placeholder: 'Enter text for the button'
                                                                }} />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid spacing={2}>
                                            <FormGroup sx={{ display: (buttonCheckbox ? ('block') : ('none')) }}>
                                                <FormControlLabel name="quick_replay_checkbox" control={<Checkbox checked={quickReplyCheckbox} onChange={handleQuickReplyCheckbox} />} label="Add Quick Replies" />
                                            </FormGroup>
                                        </Grid>
                                        {buttonCheckbox && quickReplyCheckbox && (
                                            <>
                                                <Grid spacing={2}>
                                                    {quickReplyFields.map((field) => (
                                                        <Grid item md={12} key={field.id} className="quicksection" style={{ marginTop: '10px', mb: 4, border: '1px solid #ddd', borderRadius: 10, padding: '10px' }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item md={11}>
                                                                    <FormControl variant="outlined" size="small" fullWidth>
                                                                        <FilledInput
                                                                            sx={{ mb: 1 }}
                                                                            id={`quick_reply${field.id}`}
                                                                            name={`quick_reply[${field.id}]`}
                                                                            onChange={submitTemplateForm.handleChange}
                                                                            onBlur={submitTemplateForm.handleBlur}
                                                                            endAdornment={<InputAdornment position="end">8/25</InputAdornment>}
                                                                            aria-describedby="filled-weight-helper-text"
                                                                            inputProps={{
                                                                                'aria-label': 'weight',
                                                                                placeholder: 'Enter Button text',
                                                                            }} />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item md={1} sx={{ alignItems: 'center', display: 'grid', textAlign: 'end' }}>
                                                                    <Link onClick={() => handleRemoveQuickReplyFields(field.id)}>
                                                                        <RemoveCircleIcon style={{ color: 'red' }} />
                                                                    </Link>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                {
                                                    (quickReplyFields.length >= 2) ? ('') : (
                                                        <>
                                                            <Grid container sx={{ mt: 2 }}>
                                                                <Link style={{ textDecoration: 'none', fontSize: '14px', fontWeight: 500 }} onClick={handleAddQuickReplyFields}><AddCircleIcon /> Add another quick reply</Link>
                                                            </Grid>
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item md={12} sx={{ mt: 2, mb: 3, textAlign: 'end' }}>
                                        <Button type="submit" variant="contained" sx={{ backgroundColor: '#699303', textTransform: 'capitalize' }}>
                                            Save Template
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item md={3}>
                                    <Card variant="outlined" style={cardBorderstyle}>
                                        <Grid sx={{ border: '4px solid #121515', borderWidth: '10px 4px 20px 4px', overflow: 'hidden' }}>
                                            <Grid style={headerTopstyle}>
                                                <Grid container item sx={{ alignSelf: 'center' }}>
                                                    <Grid item md={6}>
                                                        <Typography sx={{ color: '#fff', fontSize: '10px', paddingLeft: '5px', }}>{moment(new Date()).format('hh:mm')}</Typography>
                                                    </Grid>
                                                    <Grid item md={6} sx={{ textAlign: 'end', }}>
                                                        <Battery sx={{ color: '#fff', fontSize: '20px', paddingRight: '5px', marginTop: '-13px' }} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid style={bottomHeader}>
                                                <Grid container item sx={{ alignSelf: 'center' }}>
                                                    <Grid item md={6}>
                                                        <ArrowBackleft sx={{ color: '#fff', fontSize: '22px', paddingLeft: '5px', }} />
                                                    </Grid>
                                                    <Grid item md={6} sx={{ textAlign: 'end', }}>
                                                        <Landlineicon sx={{ color: '#fff', fontSize: '20px', paddingRight: '5px', }} />
                                                        <Outline sx={{ color: '#fff', fontSize: '20px', paddingRight: '5px', }} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid style={messageBody}>
                                                <Grid container item sx={{ mb: 2 }}>
                                                    <Grid item md={12}>
                                                        <Grid sx={{
                                                            textAlign: 'center', backgroundColor: '#fff', padding: '1px', borderRadius: '5px',
                                                            margin: '0 auto', width: '30%',
                                                        }}>
                                                            <Typography sx={{ color: '#5d5d5d', fontSize: '12px', fontWeight: '500' }}> Today</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item>
                                                    <Grid item md={12}>
                                                        <Grid style={messageBox}>
                                                            <Grid style={innermsg}></Grid>
                                                            <Grid sx={{ padding: '9px', }}>
                                                                <Grid item md={12} sx={{ mb: 1, }}>
                                                                    <Typography sx={{ color: '#212020', fontSize: '12px', fontWeight: '600' }}>Template for English Language</Typography>
                                                                </Grid>
                                                                <Grid style={{ maxHeight: '245px', overflowY: 'scroll', overflowX: 'hidden' }} className="custom-scrollbar">
                                                                    <Grid item md={12} sx={{ mt: 1, mb: 1, textAlign: 'center' }}>
                                                                        {submitTemplateForm.values.media_type === '2' && (
                                                                            <Typography sx={{ color: '#212020', fontSize: '12px', fontWeight: '600', textAlign: 'left' }}>{submitTemplateForm.values.header_text}</Typography>
                                                                        )}
                                                                        {(submitTemplateForm.values.headerMedia && submitTemplateForm.values.media_type === '3' && (
                                                                            <img src={URL.createObjectURL(submitTemplateForm.values.headerMedia)} alt="image" style={{
                                                                                width: '100%', height: '95px', overflow: 'hidden',
                                                                                objectFit: 'cover', textAlign: 'center', objectPosition: 'top'
                                                                            }} />
                                                                        ))}
                                                                        {(submitTemplateForm.values.headerMedia && submitTemplateForm.values.media_type === '4' && (
                                                                            <video src={URL.createObjectURL(submitTemplateForm.values.headerMedia)} alt="image" style={{
                                                                                width: '100%', height: '95px', overflow: 'hidden',
                                                                                objectFit: 'cover', textAlign: 'center', objectPosition: 'top'
                                                                            }} />
                                                                        ))}
                                                                        {(submitTemplateForm.values.headerMedia && submitTemplateForm.values.media_type === '5' && (
                                                                            <img src={Documentimg} alt="document" />
                                                                        ))}
                                                                    </Grid>
                                                                    {(quillRef.current && quillRef.current.getEditor().getText() && (
                                                                        <Typography sx={{ color: '#5d5d5d', fontSize: '12px', fontWeight: '500' }}>
                                                                            {(submitTemplateForm.values.editorContent).replace(/(<([^>]+)>)/ig, '')}
                                                                        </Typography>
                                                                    ))}
                                                                    {submitTemplateForm.values.template_footer && (
                                                                        <Typography sx={{ color: '#858585', fontSize: '13px', textAlign: 'left', marginTop: '5px', marginBottom: '5px', }}>
                                                                            {submitTemplateForm.values.template_footer}
                                                                        </Typography>
                                                                    )}
                                                                    <Typography sx={{ color: '#858585', fontSize: '10px', textAlign: 'right', marginTop: '3px', }}>
                                                                        {moment(new Date()).format('hh:mm a')}
                                                                    </Typography>
                                                                </Grid >
                                                            </Grid>
                                                            {combinedFooter}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </form >
                    <BootstrapPopupDialog
                        onClose={handlePopupClose}
                        aria-labelledby="customized-dialog-title"
                        open={popup}
                        fullWidth
                    >
                        <DialogTitle sx={{ m: 0, p: 2, fontSize: 21, fontWeight: 500 }} id="customized-dialog-title">
                            <ReportProblem style={{ fontSize: 21 }} /> Add Samples for your variables
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handlePopupClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>
                            <Typography id="header" sx={{ fontSize: 19, fontWeight: 500 }} >
                                The template you are submitting for approval has variables in it. We recommend adding samples for the variables sa that the team can understand how you would like to use them.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" sx={{ mr: 1, textTransform: 'capitalize', fontSize: 14, fontWeight: 400 }} onClick={handlePopupToggle}>
                                Add Sample
                            </Button>
                        </DialogActions>
                    </BootstrapPopupDialog>
                    <Dialog open={open} onClose={handleClose} fullWidth>
                        <DialogTitle>Sample Content</DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {submitTemplateForm.values.header_text.includes('}}') ? (<>
                            <DialogTitle>Header</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {submitTemplateForm.values.header_text}
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="headerSample"
                                    name="headerSample"
                                    label="Sample"
                                    type="text"
                                    value={submitTemplateForm.values.headerSample}
                                    onChange={submitTemplateForm.handleChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </DialogContent>
                        </>
                        ) : ('')}
                        {quillRef?.current?.getEditor()?.getText().includes('}}') ? (
                            <>
                                <DialogTitle>Body</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {(quillRef.current) ? (quillRef.current.getEditor().getText()) : ('')}
                                    </DialogContentText>
                                    {quillRef.current && ((quillRef.current.getEditor().getText().split('}}'))).map((field, index) => (
                                        <>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                value={submitTemplateForm.values.bodySample[index]}
                                                id={`bodySample${index}`}
                                                name={`bodySample[${index}]`}
                                                label="Sample"
                                                type="text"
                                                onChange={submitTemplateForm.handleChange}
                                                fullWidth
                                                variant="standard"
                                                sx={{ display: (index === (quillRef.current.getEditor().getText().split('}}').length) - 1) ? ('none') : '' }} key={index}
                                            />
                                        </>
                                    ))}
                                </DialogContent>
                            </>
                        ) : ('')}
                        {(_.some(_.compact(submitTemplateForm.values.footer_button_url_link), (str) => str.indexOf('{{', 0) !== -1)) && (
                            <>
                                <DialogTitle>Button</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="ButtonSample"
                                        name="ButtonSample"
                                        label="Sample"
                                        type="text"
                                        value={submitTemplateForm.values.ButtonSample}
                                        onChange={submitTemplateForm.handleChange}
                                        fullWidth
                                        variant="standard"
                                    />
                                </DialogContent>
                            </>
                        )}
                        <DialogActions>
                            <Button type="button" variant="contained" sx={{ mr: 1, textTransform: 'capitalize', fontSize: 14, fontWeight: 400 }} onClick={handleClose}>
                                Done
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <div style={appcontainer}>
                    <div style={loaderOverlay}>
                        <ThreeDots
                            visible={true}
                            height="96"
                            width="96"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperClass=""
                            wrapperStyle={{ justifyContent: "center" }} />
                    </div>
                </div>
            ))}
        </>
    )
}

export default CreateCarouselTemplate