export const style = {
    reportBox1: {
        // background: 'rgb(250 139 12 / 76%)',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    reportBox2: {
        // background: 'rgb(32 201 151 / 82%)',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    reportBox3: {
        // background: 'rgb(95 99 242 / 82%)',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    reportBox4: {
        // background: '#fc6e8b',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    reportBox5: {
        // background: 'rgb(244 69 139 / 77%)',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    reportBox6: {
        // background: '#2196f3',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    reportBox7: {
        // background: 'rgb(249 85 43 / 78%)',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    reportBox8: {
        // background: 'rgb(156 39 176 / 74%)',
        borderRadius: '5px',
        boxShadow: '0px 1px 8px rgb(4 4 7 / 18%)',
        width: '100%',
    },

    totalnumber: {
        fontSize: '22px',
        fontWeight: 600,
        color: 'rgb(0 0 0 / 87%)',
    },

    subHeading: {
        fontSize: '15px',
        fontWeight: 500,
        color: 'rgb(0 0 0 / 87%)',
    },

    iconStyle1: {
        backgroundColor: 'rgb(229 123 0)',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    },

    iconStyle2: {
        backgroundColor: 'rgb(38 173 133)',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    },

    iconStyle3: {
        backgroundColor: 'rgb(93 96 231)',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    },

    iconStyle4: {
        backgroundColor: '#f13e62',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    },

    iconStyle5: {
        backgroundColor: '#f13e62',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    },

    iconStyle6: {
        backgroundColor: 'rgb(11 110 189)',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    },

    iconStyle7: {
        backgroundColor: 'rgb(234 79 40)',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    },

    iconStyle8: {
        backgroundColor: 'rgb(174 51 194)',
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'grid',
        placeContent: 'center',
        transform: 'rotate(45deg)',
    }
}